






























































































import {
  IActionData,
  IBonusData,
  ICoreBonusData,
  ICounterData,
  IDeployableData,
  ILCPContent,
  ISynergyData,
} from '@tenebrae-press/lancer-types'
import Vue from 'vue'

type CoreBonusEditorData = {
  dialog: boolean
  id: string
  name: string
  effect: string
  description: string
  mounted_effect: string
  actions: Array<IActionData>
  bonuses: Array<IBonusData>
  synergies: Array<ISynergyData>
  deployables: Array<IDeployableData>
  counters: Array<ICounterData>
  integrated: Array<string>
  special_equipment: Array<string>
  isEdit: boolean
  editIndex: number
}

export default Vue.extend({
  name: 'core-bonus-editor',
  props: { manufacturer: { type: Object, required: true } },

  data: (): CoreBonusEditorData => ({
    dialog: false,
    id: '',
    name: '',
    effect: '',
    description: '',
    mounted_effect: '',
    actions: [],
    bonuses: [],
    synergies: [],
    deployables: [],
    counters: [],
    integrated: [],
    special_equipment: [],
    isEdit: false,
    editIndex: -1,
  }),
  computed: {
    confirmOK(): boolean {
      return !!this.id && !!this.name && !!this.effect && !!this.description
    },
    lcp(): ILCPContent {
      return this.$store.getters.lcp
    },
    core_bonuses(): Array<ICoreBonusData> {
      if (!this.lcp.core_bonuses) return []
      return this.lcp.core_bonuses.filter(x => x.source === this.manufacturer.id)
    },
  },
  methods: {
    addNew(): void {
      this.reset()
      this.dialog = true
    },
    submit(): void {
      const e: ICoreBonusData = {
        id: this.id,
        name: this.name,
        source: this.manufacturer.id,
        effect: this.effect,
        description: this.description,
        mounted_effect: this.mounted_effect,
        actions: this.actions,
        bonuses: this.bonuses,
        synergies: this.synergies,
        deployables: this.deployables,
        counters: this.counters,
        integrated: this.integrated,
        special_equipment: this.special_equipment,
      }
      if (this.isEdit) {
        const index = this.lcp.core_bonuses?.findIndex(x => x.id === this.id)
        if (index === undefined) {
          this.$set(this.lcp, 'core_bonuses', [e])
        } else if (index < 0) {
          this.lcp.core_bonuses?.push(e)
        } else this.$set(this.lcp.core_bonuses ?? [], index, e)
      } else {
        if (!this.lcp.core_bonuses) this.$set(this.lcp, 'core_bonuses', [])
        this.lcp.core_bonuses?.push(e)
      }
      this.reset()
      this.dialog = false
    },
    edit(cb: ICoreBonusData, index: number): void {
      this.id = cb.id || ''
      this.name = cb.name || ''
      this.effect = cb.effect || ''
      this.description = cb.description || ''
      this.mounted_effect = cb.mounted_effect || ''
      this.actions = cb.actions || []
      this.bonuses = cb.bonuses || []
      this.synergies = cb.synergies || []
      this.deployables = cb.deployables || []
      this.counters = cb.counters || []
      this.integrated = cb.integrated || []
      this.special_equipment = cb.special_equipment || []
      this.isEdit = true
      this.editIndex = index
      this.dialog = true
    },
    remove(item: ICoreBonusData): void {
      const index = this.lcp.core_bonuses?.findIndex(x => x.id === item.id)
      if (index === undefined) return
      this.lcp.core_bonuses?.splice(index, 1)
    },
    reset(): void {
      this.id = ''
      this.name = ''
      this.effect = ''
      this.description = ''
      this.mounted_effect = ''
      this.actions = []
      this.bonuses = []
      this.synergies = []
      this.deployables = []
      this.counters = []
      this.integrated = []
      this.special_equipment = []
    },
  },
})
