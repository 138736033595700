





















































































































































































































































































































































import Lancer, {
  IActionData,
  IBonusData,
  ICoreSystemData,
  IFrameData,
  IFrameStats,
  IFrameTraitData,
  ISynergyData,
  MechType,
  MountType,
} from '@tenebrae-press/lancer-types'

import Vue from 'vue'

type FrameEditorData = {
  dialog: boolean
  mechTypes: Array<MechType>
  mountTypes: Array<MountType>
  id: string
  license_level: number
  name: string
  mechtype: Array<MechType>
  description: string
  mounts: Array<MountType>
  stats: IFrameStats
  traits: Array<IFrameTraitData>
  core_system: ICoreSystemData
  active: {
    actions: Array<IActionData>
    bonuses: Array<IBonusData>
    synergies: Array<ISynergyData>
  }
  passive: {
    actions: Array<IActionData>
    bonuses: Array<IBonusData>
    synergies: Array<ISynergyData>
  }
  image_url: string
  y_pos: number
  isEdit: boolean
}

export default Vue.extend({
  name: 'frame-editor',
  props: { manufacturer: { type: Object, required: true } },

  data: (): FrameEditorData => ({
    dialog: false,
    mechTypes: Lancer.MECH_TYPES,
    mountTypes: Lancer.MOUNT_TYPES,
    id: '',
    license_level: 2,
    name: '',
    mechtype: [],
    description: '',
    mounts: [],
    stats: {
      size: 1,
      structure: 4,
      stress: 4,
      armor: 0,
      hp: 10,
      evasion: 6,
      edef: 6,
      heatcap: 6,
      repcap: 6,
      sensor_range: 10,
      tech_attack: 0,
      save: 10,
      speed: 4,
      sp: 6,
    },
    traits: [],
    core_system: {
      name: '',
      active_name: '',
      active_effect: '',
      activation: 'Free',
      description: '',
      deactivation: 'Free',
      use: 'Free',
      active_actions: [],
      active_bonuses: [],
      active_synergies: [],
      passive_name: '',
      passive_effect: '',
      passive_actions: [],
      passive_bonuses: [],
      passive_synergies: [],
      deployables: [],
      counters: [],
      special_equipment: [],
      tags: [],
    },
    active: { actions: [], bonuses: [], synergies: [] },
    passive: { actions: [], bonuses: [], synergies: [] },
    image_url: '',
    y_pos: 0,
    isEdit: false,
  }),
  computed: {
    confirmOK(): boolean {
      return !!this.id && !!this.name
    },
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    submit(): void {
      const e: IFrameData = {
        id: this.id,
        source: this.manufacturer.id,
        license_level: Number(this.license_level),
        name: this.name,
        mechtype: this.mechtype,
        description: this.description,
        mounts: this.mounts,
        stats: this.stats,
        traits: this.traits,
        core_system: this.core_system,
        image_url: this.image_url,
        y_pos: this.y_pos,
      }
      this.$emit('save', e)
      this.reset()
      this.dialog = false
    },
    edit(frame: IFrameData): void {
      this.id = frame.id
      this.license_level = Number(frame.license_level)
      this.name = frame.name ?? ''
      this.mechtype = frame.mechtype ?? 'Balanced'
      this.description = frame.description ?? ''
      this.mounts = frame.mounts ?? []
      this.stats = {
        size: frame.stats.size ? Number(frame.stats.size) : 1,
        structure: frame.stats.structure ? Number(frame.stats.structure) : 4,
        stress: frame.stats.stress ? Number(frame.stats.stress) : 4,
        armor: frame.stats.armor ? Number(frame.stats.armor) : 0,
        hp: frame.stats.hp ? Number(frame.stats.hp) : 10,
        evasion: frame.stats.evasion ? Number(frame.stats.evasion) : 6,
        edef: frame.stats.edef ? Number(frame.stats.edef) : 6,
        heatcap: frame.stats.heatcap ? Number(frame.stats.heatcap) : 6,
        repcap: frame.stats.repcap ? Number(frame.stats.repcap) : 6,
        sensor_range: frame.stats.sensor_range ? Number(frame.stats.sensor_range) : 10,
        tech_attack: frame.stats.tech_attack ? Number(frame.stats.tech_attack) : 0,
        save: frame.stats.save ? Number(frame.stats.save) : 10,
        speed: frame.stats.speed ? Number(frame.stats.speed) : 4,
        sp: frame.stats.sp ? Number(frame.stats.sp) : 6,
      }
      this.traits = frame.traits ?? []
      this.core_system = {
        description: '',
        deactivation: 'Free',
        use: 'Free',
        active_actions: [],
        active_bonuses: [],
        active_synergies: [],
        passive_name: '',
        passive_effect: '',
        passive_actions: [],
        passive_bonuses: [],
        passive_synergies: [],
        deployables: [],
        counters: [],
        special_equipment: [],
        tags: [],
        ...frame.core_system,
      }
      this.active = {
        actions: frame.core_system.active_actions ?? [],
        bonuses: frame.core_system.active_bonuses ?? [],
        synergies: frame.core_system.active_synergies ?? [],
      }
      this.passive = {
        actions: frame.core_system.passive_actions ?? [],
        bonuses: frame.core_system.passive_bonuses ?? [],
        synergies: frame.core_system.passive_synergies ?? [],
      }
      this.image_url = frame.image_url ?? ''
      this.y_pos = frame.y_pos ?? 0
      this.isEdit = true
      this.dialog = true
    },
    remove(): void {
      this.$emit('remove', this.id)
      this.dialog = false
    },
    reset(): void {
      this.id = ''
      this.license_level = 1
      this.name = ''
      this.mechtype = []
      this.description = ''
      this.mounts = []
      this.stats = {
        size: 1,
        structure: 4,
        stress: 4,
        armor: 0,
        hp: 10,
        evasion: 6,
        edef: 6,
        heatcap: 6,
        repcap: 6,
        sensor_range: 10,
        tech_attack: 0,
        save: 10,
        speed: 4,
        sp: 6,
      }
      this.traits = []
      this.core_system = {
        name: '',
        active_name: '',
        active_effect: '',
        activation: 'Free',
        description: '',
        deactivation: 'Free',
        use: 'Free',
        active_actions: [],
        active_bonuses: [],
        active_synergies: [],
        passive_name: '',
        passive_effect: '',
        passive_actions: [],
        passive_bonuses: [],
        passive_synergies: [],
        deployables: [],
        counters: [],
        special_equipment: [],
        tags: [],
      }
      this.active = { actions: [], bonuses: [], synergies: [] }
      this.passive = { actions: [], bonuses: [], synergies: [] }
      this.image_url = ''
      this.y_pos = 0
      this.isEdit = false
    },
  },
})
