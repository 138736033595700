var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_vm._l(_vm.core_bonuses, function (cb, i) {
    return _c('v-card', {
      key: "cb_card_".concat(_vm.manufacturer.id, "-").concat(i),
      staticClass: "my-2",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-toolbar', {
      staticClass: "text-h6",
      attrs: {
        "dense": ""
      }
    }, [_vm._v(_vm._s(cb.name))]), _c('v-card-text', [_c('div', {
      domProps: {
        "innerHTML": _vm._s(cb.description)
      }
    }), _c('div', {
      domProps: {
        "innerHTML": _vm._s(cb.effect)
      }
    }), cb.mounted_effect ? _c('v-card', {
      staticClass: "text-center pa-2 mt-2",
      attrs: {
        "outlined": ""
      }
    }, [_vm._v(" " + _vm._s(cb.mounted_effect) + " ")]) : _vm._e()], 1), _c('v-divider', {
      staticClass: "my-2"
    }), _c('v-card-actions', [_c('v-btn', {
      on: {
        "click": function click($event) {
          return _vm.edit(cb, i);
        }
      }
    }, [_vm._v("edit")]), _c('v-spacer'), _c('v-btn', {
      attrs: {
        "color": "red"
      },
      on: {
        "click": function click($event) {
          return _vm.remove(cb);
        }
      }
    }, [_vm._v("delete")])], 1)], 1);
  }), _c('v-row', {
    staticClass: "mt-1",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.addNew();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" add new core bonus ")], 1)], 1)], 1), _c('v-dialog', {
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "text-h6",
    attrs: {
      "dense": "",
      "color": "pink darken-4"
    }
  }, [_vm._v("Core Bonus")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "justify": "space-around",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('id-input', {
    model: {
      value: _vm.id,
      callback: function callback($$v) {
        _vm.id = $$v;
      },
      expression: "id"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "hide-details": ""
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('rich-text-editor', {
    attrs: {
      "title": "Description"
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('rich-text-editor', {
    attrs: {
      "title": "Effect"
    },
    model: {
      value: _vm.effect,
      callback: function callback($$v) {
        _vm.effect = $$v;
      },
      expression: "effect"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "Mounted Effect (Optional)",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "rows": "2",
      "auto-grow": ""
    },
    model: {
      value: _vm.mounted_effect,
      callback: function callback($$v) {
        _vm.mounted_effect = $$v;
      },
      expression: "mounted_effect"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-4"
  }), _c('v-row', {
    attrs: {
      "dense": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('i-action-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('i-bonus-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('i-deployable-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('i-counter-builder', {
    attrs: {
      "item": this
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('integrated-selector', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('special-equipment-selector', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('i-synergy-builder', {
    attrs: {
      "item": this
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("cancel")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "success darken-2",
      "disabled": !_vm.confirmOK
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.isEdit ? 'save' : 'confirm') + " ")])], 1)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }