










































































































































































































































































































import Vue from 'vue'
import { npcRole } from '@/assets/enums'
import NpcSystemEditor from './components/_NpcSystemEditor.vue'
import NpcTraitEditor from './components/_NpcTraitEditor.vue'
import NpcWeaponEditor from './components/_NpcWeaponEditor.vue'
import NpcReactionEditor from './components/_NpcReactionEditor.vue'
import NpcProtocolEditor from './components/_NpcProtocolEditor.vue'
import { ILCPContent } from '@tenebrae-press/lancer-types/src/lcp'
import { INpcFeatureData, INpcTemplateData } from '@tenebrae-press/lancer-types'

export default Vue.extend({
  name: 'npc-class-editor',
  components: {
    NpcSystemEditor,
    NpcTraitEditor,
    NpcWeaponEditor,
    NpcReactionEditor,
    NpcProtocolEditor,
  },
  computed: {
    lcp(): ILCPContent {
      return this.$store.getters.lcp
    },
    templates() {
      if (!this.$store.getters.lcp.npc_templates)
        this.$set(this.$store.getters.lcp, 'npc_templates', [])
      return this.$store.getters.lcp.npc_templates
    },
  },
  data: (): {
    roles: typeof npcRole
    selected?: INpcTemplateData
  } => ({
    roles: npcRole,
  }),
  methods: {
    colorByType(item: INpcFeatureData) {
      if (item.type === 'Weapon') return 'deep-orange darken-4'
      if (item.type === 'Trait') return 'pink darken-4'
      if (item.type === 'System') return 'green darken-3'
      return 'teal darken-4'
    },
    openByType(item: INpcFeatureData) {
      const type = item.type.toLowerCase() + 's'
      if (this.$refs && this.$refs[type]) {
        const r = this.$refs[type] as unknown as { edit: (item: INpcFeatureData) => void }
        r.edit(item)
      }
    },
    getFeatures(c: INpcTemplateData, isOptional?: boolean): Array<INpcFeatureData> {
      if (!this.lcp.npc_features) return []
      const fArr = this.lcp.npc_features.filter(
        x => x.origin.type === 'Template' && x.origin.origin_id === c.id
      )

      return isOptional ? fArr.filter(x => x.origin.optional) : fArr.filter(x => !x.origin.optional)
    },
    addNew() {
      const e: INpcTemplateData = {
        id: 'new',
        name: 'New Template',
        description: '',
        detail: '',
        allowOptional: false,
        forceOptional: false,
        optionalMin: 0,
        optionalMax: 1,
        optionalPerTier: false,
        forceClassOptional: false,
        optionalClassMin: 0,
        optionalClassMax: 1,
        optionalClassPerTier: false,
        caveat: '',
        tables: [],
        clocks: [],
        base_features: [],
        optional_features: [],
        power: 0,
      }
      if (!this.lcp.npc_templates) {
        this.$set(this.lcp, 'npc_templates', [])
      } else {
        this.lcp.npc_templates.push(e)
      }
    },
    newFeature(type: string) {
      if (this.$refs && this.$refs[type]) {
        const r = this.$refs[type] as unknown as { reset: () => void; open: () => void }
        r.reset()
        r.open()
      }
    },
    saveItem(item: INpcFeatureData) {
      if (!this.lcp.npc_features) {
        this.$set(this.lcp, 'npc_features', [item])
      } else {
        const idx = this.lcp.npc_features.findIndex(x => x.id === item.id)
        if (idx < 0) {
          this.lcp.npc_features.push(item)
        } else this.$set(this.lcp.npc_features, idx, item)
      }
    },
    removeItem(id: string) {
      const idx = this.lcp.npc_features?.findIndex(x => x.id === id)
      if (idx && idx > -1) this.lcp.npc_features?.splice(idx, 1)
    },
    _exportJSON(type: 'npc_features' | 'npc_templates') {
      const blob = new Blob([JSON.stringify(this.lcp[type])])
      const elem = window.document.createElement('a')
      elem.href = window.URL.createObjectURL(blob)
      elem.download = `${type}.json`
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    },
    exportJSON() {
      this._exportJSON('npc_templates')
      this._exportJSON('npc_features')
    },
    importJSON() {
      if (this.$refs.fileUpload) (this.$refs.fileUpload as HTMLElement).click()
    },
    importFile(evt: Event) {
      const file = (evt.target as HTMLInputElement).files?.item(0)
      if (!file) return
      const reader = new FileReader()

      reader.onload = e =>
        this.$set(this.lcp, 'npc_templates', JSON.parse(e?.target?.result?.toString() || ''))
      reader.readAsText(file)
    },
    generateStatObject() {
      return {
        armor: [0, 0, 0],
        hp: [0, 0, 0],
        evade: [0, 0, 0],
        edef: [0, 0, 0],
        heatcap: [0, 0, 0],
        speed: [0, 0, 0],
        sensor: [0, 0, 0],
        save: [0, 0, 0],
        hull: [0, 0, 0],
        agility: [0, 0, 0],
        systems: [0, 0, 0],
        engineering: [0, 0, 0],
        size: [[1], [1], [1]],
        activations: [0, 0, 0],
      }
    },
  },
})
