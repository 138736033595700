var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "fullscreen": ""
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "text-h6",
    attrs: {
      "dense": "",
      "color": "deep-purple darken-4"
    }
  }, [_vm._v(" " + _vm._s(_vm.manufacturer.id) + " Frame Editor "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !!_vm.image_url,
      expression: "!!image_url"
    }],
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" Image Preview "), _c('v-img', {
    attrs: {
      "src": _vm.image_url,
      "max-width": "300",
      "contain": ""
    }
  })], 1), _c('v-col', [_c('v-row', {
    attrs: {
      "justify": "space-around",
      "align": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('id-input', {
    model: {
      value: _vm.id,
      callback: function callback($$v) {
        _vm.id = $$v;
      },
      expression: "id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "hide-details": ""
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "License Level",
      "type": "number",
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.license_level,
      callback: function callback($$v) {
        _vm.license_level = $$v;
      },
      expression: "license_level"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-combobox', {
    attrs: {
      "label": "Mech Type",
      "dense": "",
      "hide-details": "",
      "multiple": "",
      "clearable": "",
      "items": _vm.mechTypes
    },
    model: {
      value: _vm.mechtype,
      callback: function callback($$v) {
        _vm.mechtype = $$v;
      },
      expression: "mechtype"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Image URL",
      "hide-details": ""
    },
    model: {
      value: _vm.image_url,
      callback: function callback($$v) {
        _vm.image_url = $$v;
      },
      expression: "image_url"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Mech Banner Y Position",
      "type": "number",
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.y_pos,
      callback: function callback($$v) {
        _vm.y_pos = $$v;
      },
      expression: "y_pos"
    }
  })], 1), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !!_vm.image_url,
      expression: "!!image_url"
    }],
    staticClass: "ml-auto mt-n2",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" Banner Preview "), _c('div', {
    staticClass: "grey darken-4",
    staticStyle: {
      "height": "72px",
      "border-radius": "2px"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "min-height": "100%",
      "position": "relative",
      "min-width": "100%"
    }
  }, [_c('v-img', {
    staticStyle: {
      "position": "absolute",
      "top": "0",
      "right": "0",
      "z-index": "9"
    },
    attrs: {
      "src": _vm.image_url,
      "max-height": "100%",
      "position": 'top ' + _vm.y_pos + '% left 0px'
    }
  })], 1)])])], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "caption mb-n1 mt-n3"
  }, [_vm._v("MOUNTS")]), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_vm._l(_vm.mounts, function (m, i) {
    return _c('v-chip', {
      key: "mount-".concat(i),
      staticClass: "mx-1",
      attrs: {
        "close": "",
        "close-icon": "mdi-close"
      },
      on: {
        "click:close": function clickClose($event) {
          return _vm.mounts.splice(i, 1);
        }
      }
    }, [_vm._v(" " + _vm._s(m) + " ")]);
  }), _c('v-menu', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    }])
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "text-h6",
    attrs: {
      "dense": "",
      "color": "pink darken-4"
    }
  }, [_vm._v("Add Mount")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "justify": "space-around",
      "align": "center"
    }
  }, _vm._l(_vm.mountTypes, function (mt) {
    return _c('v-col', {
      key: mt
    }, [_c('v-btn', {
      attrs: {
        "large": "",
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.mounts.push(mt);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v("mdi-plus")]), _vm._v(" " + _vm._s(mt) + " ")], 1)], 1);
  }), 1)], 1)], 1)], 1)], 2)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('rich-text-editor', {
    attrs: {
      "title": "Description"
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-2"
  }), _c('v-row', {
    staticClass: "my-2",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Structure",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.stats.structure,
      callback: function callback($$v) {
        _vm.$set(_vm.stats, "structure", $$v);
      },
      expression: "stats.structure"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Stress",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.stats.stress,
      callback: function callback($$v) {
        _vm.$set(_vm.stats, "stress", $$v);
      },
      expression: "stats.stress"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Armor",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.stats.armor,
      callback: function callback($$v) {
        _vm.$set(_vm.stats, "armor", $$v);
      },
      expression: "stats.armor"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "HP",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.stats.hp,
      callback: function callback($$v) {
        _vm.$set(_vm.stats, "hp", $$v);
      },
      expression: "stats.hp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Evasion",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.stats.evasion,
      callback: function callback($$v) {
        _vm.$set(_vm.stats, "evasion", $$v);
      },
      expression: "stats.evasion"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "E-Defense",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.stats.edef,
      callback: function callback($$v) {
        _vm.$set(_vm.stats, "edef", $$v);
      },
      expression: "stats.edef"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Heat Capacity",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.stats.heatcap,
      callback: function callback($$v) {
        _vm.$set(_vm.stats, "heatcap", $$v);
      },
      expression: "stats.heatcap"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Repair Capacity",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.stats.repcap,
      callback: function callback($$v) {
        _vm.$set(_vm.stats, "repcap", $$v);
      },
      expression: "stats.repcap"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Sensor Range",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.stats.sensor_range,
      callback: function callback($$v) {
        _vm.$set(_vm.stats, "sensor_range", $$v);
      },
      expression: "stats.sensor_range"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Tech Attack",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.stats.tech_attack,
      callback: function callback($$v) {
        _vm.$set(_vm.stats, "tech_attack", $$v);
      },
      expression: "stats.tech_attack"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Save",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.stats.save,
      callback: function callback($$v) {
        _vm.$set(_vm.stats, "save", $$v);
      },
      expression: "stats.save"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Speed",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.stats.speed,
      callback: function callback($$v) {
        _vm.$set(_vm.stats, "speed", $$v);
      },
      expression: "stats.speed"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "SP",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.stats.sp,
      callback: function callback($$v) {
        _vm.$set(_vm.stats, "sp", $$v);
      },
      expression: "stats.sp"
    }
  })], 1), _c('v-col', {
    staticClass: "ml-auto",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "items": [0.5, 1, 2, 3, 4],
      "label": "Size",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.stats.size,
      callback: function callback($$v) {
        _vm.$set(_vm.stats, "size", $$v);
      },
      expression: "stats.size"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "mb-5"
  }), _c('v-row', [_c('v-col', [_c('i-frame-trait-builder', {
    attrs: {
      "item": this
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-4"
  }), _c('v-card', {
    staticClass: "pa-2",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-toolbar', {
    staticClass: "text-h5"
  }, [_vm._v("CORE SYSTEM")]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "hide-details": ""
    },
    model: {
      value: _vm.core_system.name,
      callback: function callback($$v) {
        _vm.$set(_vm.core_system, "name", $$v);
      },
      expression: "core_system.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('rich-text-editor', {
    attrs: {
      "title": "Description"
    },
    model: {
      value: _vm.core_system.description,
      callback: function callback($$v) {
        _vm.$set(_vm.core_system, "description", $$v);
      },
      expression: "core_system.description"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('i-counter-builder', {
    attrs: {
      "item": _vm.core_system
    }
  })], 1), _c('v-col', [_c('i-deployable-builder', {
    attrs: {
      "item": _vm.core_system
    }
  })], 1), _c('v-col', [_c('integrated-selector', {
    attrs: {
      "item": _vm.core_system
    }
  })], 1), _c('v-col', [_c('special-equipment-selector', {
    attrs: {
      "item": _vm.core_system
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('tag-selector', {
    attrs: {
      "item": _vm.core_system
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-5"
  }), _c('v-card', {
    staticClass: "ma-2",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-toolbar', {
    staticClass: "text-h6",
    attrs: {
      "dense": "",
      "color": "black"
    }
  }, [_vm._v("ACTIVE")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Active Name",
      "hide-details": ""
    },
    model: {
      value: _vm.core_system.active_name,
      callback: function callback($$v) {
        _vm.$set(_vm.core_system, "active_name", $$v);
      },
      expression: "core_system.active_name"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "items": ['Turn', 'Next Turn', 'Round', 'Next Round', 'Scene', 'Encounter', 'Mission'],
      "label": "Use",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.core_system.use,
      callback: function callback($$v) {
        _vm.$set(_vm.core_system, "use", $$v);
      },
      expression: "core_system.use"
    }
  })], 1), _c('v-col', [_c('activator-selector', {
    attrs: {
      "item": _vm.core_system
    }
  })], 1), _c('v-col', [_c('activator-selector', {
    attrs: {
      "field": "deactivation",
      "label": "Deactivation",
      "optional": "",
      "item": _vm.core_system
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('rich-text-editor', {
    attrs: {
      "title": "Active Effect"
    },
    model: {
      value: _vm.core_system.active_effect,
      callback: function callback($$v) {
        _vm.$set(_vm.core_system, "active_effect", $$v);
      },
      expression: "core_system.active_effect"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "dense": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('i-action-builder', {
    attrs: {
      "item": _vm.active
    }
  })], 1), _c('v-col', [_c('i-bonus-builder', {
    attrs: {
      "item": _vm.active
    }
  })], 1), _c('v-col', [_c('i-synergy-builder', {
    attrs: {
      "item": _vm.active
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "ma-2",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-toolbar', {
    staticClass: "text-h6",
    attrs: {
      "dense": "",
      "color": "black"
    }
  }, [_vm._v(" PASSIVE "), _c('span', {
    staticClass: "caption pl-2"
  }, [_vm._v("(Optional)")])]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Passive Name",
      "hide-details": ""
    },
    model: {
      value: _vm.core_system.passive_name,
      callback: function callback($$v) {
        _vm.$set(_vm.core_system, "passive_name", $$v);
      },
      expression: "core_system.passive_name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('rich-text-editor', {
    attrs: {
      "title": "Passive Effect"
    },
    model: {
      value: _vm.core_system.passive_effect,
      callback: function callback($$v) {
        _vm.$set(_vm.core_system, "passive_effect", $$v);
      },
      expression: "core_system.passive_effect"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "dense": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('i-action-builder', {
    attrs: {
      "item": _vm.passive
    }
  })], 1), _c('v-col', [_c('i-bonus-builder', {
    attrs: {
      "item": _vm.passive
    }
  })], 1), _c('v-col', [_c('i-synergy-builder', {
    attrs: {
      "item": _vm.passive
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("cancel")]), _c('v-spacer'), _vm.isEdit ? _c('v-btn', {
    attrs: {
      "color": "error darken-2"
    },
    on: {
      "click": _vm.remove
    }
  }, [_vm._v("Delete")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": "success darken-2",
      "disabled": !_vm.confirmOK
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.isEdit ? 'save' : 'confirm') + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }