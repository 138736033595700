var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "fullscreen": ""
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "text-h6",
    attrs: {
      "dense": "",
      "color": "deep-purple darken-4"
    }
  }, [_vm._v(" " + _vm._s(_vm.manufacturer ? _vm.manufacturer.id : '') + " System Editor "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', [_c('v-row', {
    attrs: {
      "justify": "space-around",
      "align": "end"
    }
  }, [_c('v-col', [_c('id-input', {
    model: {
      value: _vm.id,
      callback: function callback($$v) {
        _vm.id = $$v;
      },
      expression: "id"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "hide-details": ""
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "label": "Type",
      "items": _vm.systemTypes,
      "hide-details": ""
    },
    model: {
      value: _vm.type,
      callback: function callback($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  })], 1), _c('v-col', [_c('v-combobox', {
    attrs: {
      "label": "License",
      "dense": "",
      "hide-details": "",
      "items": _vm.licenses
    },
    model: {
      value: _vm.license,
      callback: function callback($$v) {
        _vm.license = $$v;
      },
      expression: "license"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "License Level",
      "type": "number",
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.license_level,
      callback: function callback($$v) {
        _vm.license_level = $$v;
      },
      expression: "license_level"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "SP Cost",
      "type": "number",
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.sp,
      callback: function callback($$v) {
        _vm.sp = $$v;
      },
      expression: "sp"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('rich-text-editor', {
    attrs: {
      "title": "Effect"
    },
    model: {
      value: _vm.effect,
      callback: function callback($$v) {
        _vm.effect = $$v;
      },
      expression: "effect"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('rich-text-editor', {
    attrs: {
      "title": "Description"
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('i-action-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('i-bonus-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('i-deployable-builder', {
    attrs: {
      "item": this
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('i-synergy-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('i-counter-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('integrated-selector', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('special-equipment-selector', {
    attrs: {
      "item": this
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('tag-selector', {
    attrs: {
      "item": this
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("cancel")]), _c('v-spacer'), _vm.isEdit ? _c('v-btn', {
    attrs: {
      "color": "error darken-2"
    },
    on: {
      "click": _vm.remove
    }
  }, [_vm._v("Delete")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": "success darken-2",
      "disabled": !_vm.confirmOK
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.isEdit ? 'save' : 'confirm') + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }