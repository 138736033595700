var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('div', {
    staticClass: "overline"
  }, [_vm._v("Quick Navigation")]), _c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-around",
      "align": "center"
    }
  }, _vm._l(_vm.lcpContentKey(_vm.itemKey), function (item, i) {
    return _c('v-col', {
      key: "btn".concat(String(i)),
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      attrs: {
        "text": ""
      },
      on: {
        "click": function click($event) {
          return _vm.$vuetify.goTo("#item_".concat(i));
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1);
  }), 1), _c('v-divider', {
    staticClass: "my-3"
  }), _c('v-row', _vm._l(_vm.lcpContentKey(_vm.itemKey), function (item, i) {
    return _c('v-col', {
      key: "item_".concat(String(i)),
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      attrs: {
        "id": "item_".concat(String(i))
      }
    }, [_vm._t("default", null, {
      "item": item
    }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
      on: {
        "click": function click($event) {
          return _vm.duplicateItem(item);
        }
      }
    }, [_vm._v("Duplicate")]), _c('v-btn', {
      attrs: {
        "color": "error darken-2"
      },
      on: {
        "click": function click($event) {
          return _vm.deleteItem(item);
        }
      }
    }, [_vm._v("Delete")])], 1)], 2)], 1);
  }), 1), _c('v-divider', {
    staticClass: "my-3"
  }), _c('div', {
    staticClass: "px-8"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary darken-2",
      "block": "",
      "x-large": ""
    },
    on: {
      "click": _vm.addNew
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" Add New ")], 1)], 1), _c('v-divider', {
    staticClass: "my-3"
  }), _c('v-alert', {
    staticClass: "text-center",
    attrs: {
      "icon": _vm.errors.length ? 'mdi-alert' : 'mdi-check',
      "prominent": "",
      "color": _vm.errors.length ? 'error darken-2' : 'success darken-2'
    }
  }, [_c('code', {
    staticClass: "error-msg"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.errors.length ? _vm.errors.join('<br>') : 'No errors detected!')
    }
  })])]), _c('div', {
    staticStyle: {
      "min-height": "100px"
    },
    attrs: {
      "id": "end"
    }
  }), _c('v-footer', {
    attrs: {
      "fixed": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "to": "/"
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")]), _vm._v(" back ")], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$vuetify.goTo(0);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-up")])], 1), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$vuetify.goTo('#end');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-down")])], 1), _c('v-spacer'), _c('input', {
    ref: "fileUpload",
    attrs: {
      "type": "file",
      "accept": ".json",
      "hidden": ""
    },
    on: {
      "change": _vm.importFile
    }
  }), _c('v-btn', {
    staticClass: "mx-1",
    attrs: {
      "outlined": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.exportJson();
      }
    }
  }, [_vm._v("Export JSON File")]), _c('v-btn', {
    staticClass: "mx-1",
    attrs: {
      "outlined": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.importJson();
      }
    }
  }, [_vm._v("Import JSON File")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }