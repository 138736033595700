var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "my-2"
  }, [_c('v-card-text', [_c('v-row', _vm._l(_vm.sourcelessItems, function (item, i) {
    return _c('v-col', {
      key: "item_".concat(i)
    }, [_c('v-btn', {
      attrs: {
        "block": "",
        "color": _vm.colorByType(item)
      },
      on: {
        "click": function click($event) {
          return _vm.openByType(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1);
  }), 1)], 1)], 1), _c('v-divider', {
    staticClass: "mt-4"
  }), _c('v-row', {
    staticClass: "mt-1",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "deep-orange darken-4"
    },
    on: {
      "click": function click($event) {
        return _vm.newItem('weapons');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" add new weapon ")], 1)], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "teal darken-4"
    },
    on: {
      "click": function click($event) {
        return _vm.newItem('systems');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" add new system ")], 1)], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "cyan darken-3"
    },
    on: {
      "click": function click($event) {
        return _vm.newItem('mods');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" add new mod ")], 1)], 1)], 1), _c('system-editor', {
    ref: "systems",
    on: {
      "save": function save($event) {
        return _vm.saveItem('systems', $event);
      },
      "remove": function remove($event) {
        return _vm.removeItem('systems', $event);
      }
    }
  }), _c('mod-editor', {
    ref: "mods",
    on: {
      "save": function save($event) {
        return _vm.saveItem('mods', $event);
      },
      "remove": function remove($event) {
        return _vm.removeItem('mods', $event);
      }
    }
  }), _c('weapon-editor', {
    ref: "weapons",
    on: {
      "save": function save($event) {
        return _vm.saveItem('weapons', $event);
      },
      "remove": function remove($event) {
        return _vm.removeItem('weapons', $event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }