















































import Vue from 'vue'
import EditorBase from './EditorBase.vue'

import SynergySelector from '@/components/SynergyLocationSelector.vue'

export default Vue.extend({
  name: 'tags-editor',
  components: {
    EditorBase,
    SynergySelector,
  },
})
