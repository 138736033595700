













































































import {
  IActionData,
  IBonusData,
  ICounterData,
  IDeployableData,
  INpcClassData,
  INpcTemplateData,
  ISynergyData,
  ITagData,
  IClockData,
  IOriginData,
  INpcReactionData,
} from '@tenebrae-press/lancer-types'
import Vue from 'vue'

type NpcReactionEditorData = {
  dialog: boolean
  optional: boolean
  hide_active: boolean
  id: string
  name: string
  recharge: number
  trigger: string
  effect: string
  type: 'Reaction'
  tags: Array<ITagData>
  actions: Array<IActionData>
  bonuses: Array<IBonusData>
  synergies: Array<ISynergyData>
  deployables: Array<IDeployableData>
  counters: Array<ICounterData>
  clocks: Array<IClockData>
  isEdit: boolean
}

export default Vue.extend({
  name: 'npc-trait-editor',
  props: {
    npcClass: { type: Object as () => INpcClassData, required: false },
    npcTemplate: { type: Object as () => INpcTemplateData, required: false },
  },
  data: (): NpcReactionEditorData => ({
    dialog: false,
    optional: false,
    hide_active: false,
    id: '',
    name: '',
    recharge: 0,
    trigger: '',
    effect: '',
    type: 'Reaction',
    tags: [],
    actions: [],
    bonuses: [],
    synergies: [],
    deployables: [],
    counters: [],
    clocks: [],
    isEdit: false,
  }),
  computed: {
    origin(): IOriginData {
      if (this.npcClass || this.npcTemplate)
        return {
          type: this.npcClass ? 'Class' : 'Template',
          name: this[this.npcClass ? 'npcClass' : 'npcTemplate'].name,
          base: false,
          optional: this.optional,
          origin_id: this.npcClass ? this.npcClass.id : this.npcTemplate.id,
        }
      else
        return {
          type: 'Generic',
          name: '',
          base: false,
          optional: false,
        }
    },
    confirmOK(): boolean {
      return !!this.id && !!this.name
    },
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    submit(): void {
      const e: INpcReactionData = {
        id: this.id,
        name: this.name,
        origin: this.origin,
        recharge: this.recharge,
        optional: this.optional,
        hide_active: this.hide_active,
        trigger: this.trigger,
        effect: this.effect,
        type: this.type,
        tags: this.tags,
        actions: this.actions,
        bonuses: this.bonuses,
        synergies: this.synergies,
        deployables: this.deployables,
        counters: this.counters,
        clocks: this.clocks,
        locked: false,
      }
      this.$emit('save', e)
      this.reset()
      this.dialog = false
    },
    edit(trait: NpcReactionEditorData): void {
      this.id = trait.id
      this.name = trait.name
      this.trigger = trait.trigger
      this.effect = trait.effect
      this.type = trait.type
      this.optional = trait.optional
      this.hide_active = trait.hide_active
      this.tags = trait.tags
      this.actions = trait.actions
      this.bonuses = trait.bonuses
      this.synergies = trait.synergies
      this.deployables = trait.deployables
      this.counters = trait.counters
      this.clocks = trait.clocks
      this.isEdit = true
      this.dialog = true
    },
    remove(): void {
      this.$emit('remove', this.id)
      this.dialog = false
    },
    reset(): void {
      this.id = ''
      this.name = ''
      this.trigger = ''
      this.effect = ''
      this.type = 'Reaction'
      this.recharge = 0
      this.optional = false
      this.hide_active = false
      this.tags = []
      this.actions = []
      this.bonuses = []
      this.synergies = []
      this.deployables = []
      this.counters = []
      this.clocks = []
      this.isEdit = false
    },
  },
})
