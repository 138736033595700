var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('editor-base', {
    attrs: {
      "itemKey": "actions",
      "checkDupes": ['id', 'name'],
      "checkEmpty": ['id', 'name', 'activation', 'detail']
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-toolbar', {
          staticClass: "white--text text-h6",
          attrs: {
            "dense": "",
            "color": "primary"
          }
        }, [_vm._v(_vm._s(item.name))]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('id-input', {
          model: {
            value: item.id,
            callback: function callback($$v) {
              _vm.$set(item, "id", $$v);
            },
            expression: "item.id"
          }
        })], 1), _c('v-col', [_c('v-text-field', {
          attrs: {
            "label": "Name"
          },
          model: {
            value: item.name,
            callback: function callback($$v) {
              _vm.$set(item, "name", $$v);
            },
            expression: "item.name"
          }
        })], 1), _c('v-col', [_c('activator-selector', {
          attrs: {
            "item": item
          }
        })], 1)], 1), _c('v-text-field', {
          attrs: {
            "label": "Short Description"
          },
          model: {
            value: item.terse,
            callback: function callback($$v) {
              _vm.$set(item, "terse", $$v);
            },
            expression: "item.terse"
          }
        }), _c('rich-text-editor', {
          attrs: {
            "title": "Long Description"
          },
          model: {
            value: item.detail,
            callback: function callback($$v) {
              _vm.$set(item, "detail", $$v);
            },
            expression: "item.detail"
          }
        }), item.activation !== 'Downtime' ? _c('v-row', {
          staticClass: "px-4 mb-2",
          attrs: {
            "dense": ""
          }
        }, [_c('v-col', [_c('v-switch', {
          attrs: {
            "dense": "",
            "hide-details": "",
            "label": "Pilot Action"
          },
          model: {
            value: item.pilot,
            callback: function callback($$v) {
              _vm.$set(item, "pilot", $$v);
            },
            expression: "item.pilot"
          }
        })], 1), _c('v-col', [_c('v-switch', {
          attrs: {
            "dense": "",
            "hide-details": "",
            "label": "Mech Action"
          },
          model: {
            value: item.mech,
            callback: function callback($$v) {
              _vm.$set(item, "mech", $$v);
            },
            expression: "item.mech"
          }
        })], 1), _c('v-col', [_c('v-switch', {
          attrs: {
            "dense": "",
            "hide-details": "",
            "label": "Ignore 'Used' State"
          },
          model: {
            value: item.ignore_used,
            callback: function callback($$v) {
              _vm.$set(item, "ignore_used", $$v);
            },
            expression: "item.ignore_used"
          }
        })], 1), _c('v-col', [_c('v-switch', {
          attrs: {
            "dense": "",
            "hide-details": "",
            "label": "Incur Heat Cost"
          },
          model: {
            value: item.heat_cost,
            callback: function callback($$v) {
              _vm.$set(item, "heat_cost", $$v);
            },
            expression: "item.heat_cost"
          }
        })], 1)], 1) : _vm._e(), _c('v-row', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-col', [_c('synergy-location-selector', {
          attrs: {
            "item": item
          }
        })], 1)], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }