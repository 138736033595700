var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-list', {
    attrs: {
      "nav": "",
      "dense": ""
    }
  }, [_vm._l(_vm.allManufacturers, function (m, i) {
    return _c('v-list-item', {
      key: "".concat(m.id, "_").concat(i),
      class: _vm.selected && _vm.selected !== 'none' && _vm.selected.id === m.id ? 'primary darken-3' : '',
      attrs: {
        "selectable": ""
      },
      on: {
        "click": function click($event) {
          _vm.selected = m;
        }
      }
    }, [_c('v-list-item-content', {
      staticClass: "mt-n2"
    }, [_c('v-list-item-title', [_c('span', {
      staticClass: "text-h6 mr-1"
    }, [_vm._v(_vm._s(m.id))]), _vm._v(" " + _vm._s(m.name) + " ")]), _c('v-list-item-action-text', {
      staticClass: "mt-n2"
    }, [_vm._v(" " + _vm._s(_vm.itemsByMID(m.id, 'core_bonuses').length) + " core bonuses "), _c('br'), _vm._v(" " + _vm._s(_vm.itemsByMID(m.id, 'frames').length) + " frames with " + _vm._s(_vm.itemsByMID(m.id, 'weapons').length) + " weapons, " + _vm._s(_vm.itemsByMID(m.id, 'systems').length) + " systems, " + _vm._s(_vm.itemsByMID(m.id, 'mods').length) + " mods ")])], 1)], 1);
  }), _c('v-divider'), _c('v-list-item', {
    class: _vm.selected && _vm.selected === 'none' ? 'primary darken-3' : '',
    attrs: {
      "selectable": ""
    },
    on: {
      "click": function click($event) {
        _vm.selected = 'none';
      }
    }
  }, [_c('v-list-item-content', {
    staticClass: "mt-n2"
  }, [_c('v-list-item-title', [_c('span', {
    staticClass: "text-h6 mr-1"
  }, [_vm._v("No Source")])]), _c('v-list-item-action-text', {
    staticClass: "mt-n2"
  }, [_vm._v(" " + _vm._s(_vm.itemsByMID('', 'weapons').length) + " weapons, " + _vm._s(_vm.itemsByMID('', 'systems').length) + " systems, " + _vm._s(_vm.itemsByMID('', 'mods').length) + " mods ")])], 1)], 1)], 2), _c('v-divider', {
    staticClass: "my-2"
  }), _c('v-btn', {
    attrs: {
      "block": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.addNew
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")])], 1)], 1), _c('v-col', [_vm.selected === 'none' ? _c('v-container', [_c('p', {
    staticClass: "text-center grey darken-4 pa-2"
  }, [_vm._v(" The following equipment does not include a "), _c('code', [_vm._v("Source")]), _vm._v(" field. This is used to add integrated equipment that does not appear anywhere besides the parent frame or equipment, and is not normally independently selectable by a player. ")]), _c('sourceless-equipment')], 1) : _vm.selected ? _c('v-container', [_c('v-expansion-panels', {
    attrs: {
      "focusable": "",
      "accordion": ""
    },
    model: {
      value: _vm.panels,
      callback: function callback($$v) {
        _vm.panels = $$v;
      },
      expression: "panels"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("Manufacturer Information")]), _c('v-expansion-panel-content', [_c('v-alert', {
    staticClass: "mt-2",
    attrs: {
      "outlined": "",
      "text": "",
      "color": "primary"
    }
  }, [_c('v-row', {
    attrs: {
      "dense": "",
      "justify": "space-around"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "hide-details": "",
      "label": "ID",
      "readonly": _vm.isCore(_vm.selected.id)
    },
    model: {
      value: _vm.selected.id,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "id", $$v);
      },
      expression: "selected.id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('v-text-field', {
    attrs: {
      "hide-details": "",
      "label": "Name",
      "readonly": _vm.isCore(_vm.selected.id)
    },
    model: {
      value: _vm.selected.name,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "name", $$v);
      },
      expression: "selected.name"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": "",
      "justify": "space-around",
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "persistent-hint": "",
      "hint": "SVG",
      "label": "Logo URL",
      "readonly": _vm.isCore(_vm.selected.id)
    },
    model: {
      value: _vm.selected.logo_url,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "logo_url", $$v);
      },
      expression: "selected.logo_url"
    }
  }), _c('v-img', {
    attrs: {
      "src": _vm.selected.logo_url,
      "max-height": "222",
      "max-width": "450",
      "contain": ""
    }
  })], 1), _c('v-col', [_c('color-selector', {
    attrs: {
      "title": "Light Color",
      "disabled": _vm.isCore(_vm.selected.id)
    },
    model: {
      value: _vm.selected.light,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "light", $$v);
      },
      expression: "selected.light"
    }
  })], 1), _c('v-col', [_c('color-selector', {
    attrs: {
      "title": "Dark Color",
      "disabled": _vm.isCore(_vm.selected.id)
    },
    model: {
      value: _vm.selected.dark,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "dark", $$v);
      },
      expression: "selected.dark"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('rich-text-editor', {
    attrs: {
      "title": "Flavor Quote"
    },
    model: {
      value: _vm.selected.quote,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "quote", $$v);
      },
      expression: "selected.quote"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('rich-text-editor', {
    attrs: {
      "title": "Description"
    },
    model: {
      value: _vm.selected.description,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "description", $$v);
      },
      expression: "selected.description"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("Core Bonuses")]), _c('v-expansion-panel-content', [_c('core-bonus-editor', {
    attrs: {
      "manufacturer": _vm.selected
    }
  })], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("Licenses and Equipment")]), _c('v-expansion-panel-content', [_c('license-editor', {
    attrs: {
      "manufacturer": _vm.selected
    }
  })], 1)], 1)], 1), _c('div', {
    staticStyle: {
      "min-height": "50px"
    }
  })], 1) : _c('div', [_c('v-row', {
    staticStyle: {
      "height": "50vh"
    },
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-h2 text--disabled",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("Select a Manufacturer")])], 1)], 1)], 1)], 1), _c('v-footer', {
    attrs: {
      "fixed": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "to": "/"
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")]), _vm._v(" back ")], 1), _c('v-spacer'), _c('input', {
    ref: "fileUpload",
    attrs: {
      "type": "file",
      "accept": ".json",
      "hidden": ""
    },
    on: {
      "change": _vm.importFile
    }
  }), _c('v-menu', {
    attrs: {
      "bottom": "",
      "open-on-hover": "",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mx-1",
          attrs: {
            "outlined": "",
            "small": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Export JSON File")])];
      }
    }])
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.exportJSON('manufacturers');
      }
    }
  }, [_c('v-list-item-title', [_vm._v("Export Manufacturers")])], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.exportJSON('frames');
      }
    }
  }, [_c('v-list-item-title', [_vm._v("Export Frames")])], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.exportJSON('core_bonuses');
      }
    }
  }, [_c('v-list-item-title', [_vm._v("Export Core Bonuses")])], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.exportJSON('weapons');
      }
    }
  }, [_c('v-list-item-title', [_vm._v("Export Weapons")])], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.exportJSON('systems');
      }
    }
  }, [_c('v-list-item-title', [_vm._v("Export Systems")])], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.exportJSON('mods');
      }
    }
  }, [_c('v-list-item-title', [_vm._v("Export Mods")])], 1)], 1)], 1), _c('v-menu', {
    attrs: {
      "bottom": "",
      "open-on-hover": "",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mx-1",
          attrs: {
            "outlined": "",
            "small": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Import JSON File")])];
      }
    }])
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.importJSON('manufacturers');
      }
    }
  }, [_c('v-list-item-title', [_vm._v("Import Manufacturers")])], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.importJSON('frames');
      }
    }
  }, [_c('v-list-item-title', [_vm._v("Import Frames")])], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.importJSON('core_bonuses');
      }
    }
  }, [_c('v-list-item-title', [_vm._v("Import Core Bonuses")])], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.importJSON('weapons');
      }
    }
  }, [_c('v-list-item-title', [_vm._v("Import Weapons")])], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.importJSON('systems');
      }
    }
  }, [_c('v-list-item-title', [_vm._v("Import Systems")])], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.importJSON('mods');
      }
    }
  }, [_c('v-list-item-title', [_vm._v("Import Mods")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }