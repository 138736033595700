

































import Vue from 'vue'
import EditorBase from './EditorBase.vue'

import IRankBuilder from '@/components/IRankBuilder.vue'

export default Vue.extend({
  name: 'skills-editor',
  components: { EditorBase, IRankBuilder },
})
