var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_vm._l(_vm.tables, function (t) {
    return _c('v-card', {
      key: t.key,
      staticClass: "my-5",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-toolbar', {
      staticClass: "text-h6",
      attrs: {
        "dense": "",
        "color": "primary darken-2"
      }
    }, [_vm._v(_vm._s(t.name))]), _c('div', {
      staticClass: "caption text-right"
    }, [_vm._v(_vm._s(t.location))]), _c('v-card-text', _vm._l(_vm.lcpTables(t.key), function (item, i) {
      return _c('v-row', {
        key: "item_".concat(t.key, "_").concat(i),
        attrs: {
          "dense": ""
        }
      }, [_c('v-col', [_c('v-text-field', {
        attrs: {
          "dense": "",
          "hide-details": "",
          "outlined": ""
        },
        model: {
          value: _vm.lcpTables(t.key)[i],
          callback: function callback($$v) {
            _vm.$set(_vm.lcpTables(t.key), i, $$v);
          },
          expression: "lcpTables(t.key)[i]"
        }
      })], 1), _c('v-col', {
        attrs: {
          "cols": "auto"
        }
      }, [_c('v-btn', {
        attrs: {
          "icon": "",
          "color": "error"
        },
        on: {
          "click": function click($event) {
            return _vm.deleteItem(t.key, i);
          }
        }
      }, [_c('v-icon', {
        attrs: {
          "large": ""
        }
      }, [_vm._v("mdi-close")])], 1)], 1)], 1);
    }), 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
      attrs: {
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.addNew(t.key);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v("mdi-plus")]), _vm._v(" Add New ")], 1)], 1)], 1);
  }), _c('div', {
    staticStyle: {
      "min-height": "100px"
    },
    attrs: {
      "id": "end"
    }
  }), _c('v-footer', {
    attrs: {
      "fixed": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "to": "/"
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")]), _vm._v(" back ")], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$vuetify.goTo(0);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-up")])], 1), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$vuetify.goTo('#end');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-down")])], 1), _c('v-spacer'), _c('input', {
    ref: "fileUpload",
    attrs: {
      "type": "file",
      "accept": ".json",
      "hidden": ""
    },
    on: {
      "change": _vm.importFile
    }
  }), _c('v-btn', {
    staticClass: "mx-1",
    attrs: {
      "outlined": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.exportJson();
      }
    }
  }, [_vm._v("Export JSON File")]), _c('v-btn', {
    staticClass: "mx-1",
    attrs: {
      "outlined": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.importJson();
      }
    }
  }, [_vm._v("Import JSON File")])], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }