


















import Vue from 'vue'
import EditorBase from './EditorBase.vue'

export default Vue.extend({
  name: 'backgrounds-editor',
  components: { EditorBase },
})
