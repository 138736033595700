var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('editor-base', {
    attrs: {
      "itemKey": "skills",
      "checkDupes": ['id', 'name'],
      "checkEmpty": ['id', 'name', 'description', 'detail', 'family']
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-toolbar', {
          staticClass: "white--text text-h6",
          attrs: {
            "dense": "",
            "color": "primary"
          }
        }, [_vm._v(_vm._s(item.name))]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('id-input', {
          model: {
            value: item.id,
            callback: function callback($$v) {
              _vm.$set(item, "id", $$v);
            },
            expression: "item.id"
          }
        })], 1), _c('v-col', [_c('v-text-field', {
          attrs: {
            "label": "Name"
          },
          model: {
            value: item.name,
            callback: function callback($$v) {
              _vm.$set(item, "name", $$v);
            },
            expression: "item.name"
          }
        })], 1), _c('v-col', [_c('v-select', {
          attrs: {
            "label": "Skill Type",
            "items": ['str', 'con', 'int', 'dex', 'cha'],
            "hint": "This deremines where on the Skill Trigger list the item appears and has no mechanical effect",
            "persistent-hint": ""
          },
          model: {
            value: item.family,
            callback: function callback($$v) {
              _vm.$set(item, "family", $$v);
            },
            expression: "item.family"
          }
        })], 1)], 1), _c('v-text-field', {
          attrs: {
            "label": "Short Description"
          },
          model: {
            value: item.description,
            callback: function callback($$v) {
              _vm.$set(item, "description", $$v);
            },
            expression: "item.description"
          }
        }), _c('rich-text-editor', {
          attrs: {
            "title": "Long Description"
          },
          model: {
            value: item.detail,
            callback: function callback($$v) {
              _vm.$set(item, "detail", $$v);
            },
            expression: "item.detail"
          }
        })], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }