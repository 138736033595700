










































































































import TieredStatInput from '@/components/TieredStatInput.vue'
import Lancer, {
  IActionData,
  IBonusData,
  IClockData,
  ICounterData,
  IDeployableData,
  INpcDamageData,
  INpcWeaponData,
  IOriginData,
  IRangeData,
  ISynergyData,
  ITagData,
  WeaponSize,
  WeaponType,
} from '@tenebrae-press/lancer-types'
import Vue from 'vue'

type NpcWeaponEditorData = {
  dialog: boolean
  weaponTypes: Array<WeaponType>
  weaponSizes: Array<WeaponSize>
  id: string
  name: string
  recharge: number
  optional: boolean
  type: 'Weapon'
  effect: string
  on_attack: string
  on_hit: string
  on_crit: string
  weapon_size: WeaponSize
  weapon_type: WeaponType
  damage: Array<INpcDamageData>
  range: Array<IRangeData>
  attack_bonus: Array<number>
  accuracy: Array<number>
  tags: Array<ITagData>
  actions: Array<IActionData>
  bonuses: Array<IBonusData>
  synergies: Array<ISynergyData>
  deployables: Array<IDeployableData>
  counters: Array<ICounterData>
  clocks: Array<IClockData>
  isEdit: boolean
  origin?: IOriginData
}

export default Vue.extend({
  components: { TieredStatInput },
  name: 'npc-weapon-editor',
  props: {
    npcClass: { type: Object, required: false },
    npcTemplate: { type: Object, required: false },
  },
  data: (): NpcWeaponEditorData => ({
    dialog: false,
    weaponTypes: Lancer.WEAPON_TYPES,
    weaponSizes: Lancer.WEAPON_SIZES,
    id: '',
    name: '',
    recharge: 0,
    optional: false,
    type: 'Weapon',
    effect: '',
    on_attack: '',
    on_hit: '',
    on_crit: '',
    weapon_size: 'Auxiliary',
    weapon_type: 'Melee',
    damage: [],
    range: [],
    attack_bonus: [0, 0, 0],
    accuracy: [0, 0, 0],
    tags: [],
    actions: [],
    bonuses: [],
    synergies: [],
    deployables: [],
    counters: [],
    clocks: [],
    isEdit: false,
  }),
  computed: {
    confirmOK(): boolean {
      return !!this.id && !!this.name
    },
    origin(): IOriginData {
      if (this.npcClass || this.npcTemplate)
        return {
          type: this.npcClass ? 'Class' : 'Template',
          name: this[this.npcClass ? 'npcClass' : 'npcTemplate'].name,
          optional: this.optional,
          base: false,
          origin_id: this[this.npcClass ? 'npcClass' : 'npcTemplate'].id,
        }
      else
        return {
          type: 'Generic',
          base: false,
          name: '',
          optional: false,
        }
    },
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    submit(): void {
      const e: INpcWeaponData = {
        id: this.id,
        name: this.name,
        origin: this.origin,
        effect: this.effect,
        recharge: this.recharge,
        optional: this.optional,
        damage: this.damage ?? [],
        range: this.range,
        on_attack: this.on_attack,
        on_hit: this.on_hit,
        on_crit: this.on_crit,
        weapon_size: this.weapon_size,
        weapon_type: this.weapon_type,
        attack_bonus: this.attack_bonus,
        accuracy: this.accuracy,
        type: this.type,
        tags: this.tags,
        actions: this.actions,
        bonuses: this.bonuses,
        synergies: this.synergies,
        deployables: this.deployables,
        counters: this.counters,
        clocks: this.clocks,
        locked: false,
        hide_active: false,
      }
      this.$emit('save', e)
      this.reset()
      this.dialog = false
    },
    edit(weapon: NpcWeaponEditorData): void {
      this.id = weapon.id
      this.name = weapon.name
      this.effect = weapon.effect
      this.recharge = weapon.recharge
      this.optional = weapon.origin?.optional || false
      this.damage = weapon.damage
      this.range = weapon.range
      this.on_attack = weapon.on_attack
      this.on_hit = weapon.on_hit
      this.on_crit = weapon.on_crit
      this.weapon_size = weapon.weapon_size
      this.weapon_type = weapon.weapon_type
      this.attack_bonus = weapon.attack_bonus
      this.accuracy = Array.isArray(weapon.accuracy)
        ? weapon.accuracy
        : Array(3).fill(weapon.accuracy)
      this.type = weapon.type
      this.tags = weapon.tags
      this.actions = weapon.actions
      this.bonuses = weapon.bonuses
      this.synergies = weapon.synergies
      this.deployables = weapon.deployables
      this.counters = weapon.counters
      this.clocks = weapon.clocks
      this.isEdit = true
      this.dialog = true
    },
    remove(): void {
      this.$emit('remove', this.id)
      this.dialog = false
    },
    reset(): void {
      this.id = ''
      this.name = ''
      this.type = 'Weapon'
      this.effect = ''
      this.damage = []
      this.range = []
      this.on_attack = ''
      this.on_hit = ''
      this.on_crit = ''
      this.weapon_size = 'Auxiliary'
      this.weapon_type = 'Melee'
      this.attack_bonus = [0, 0, 0]
      this.accuracy = [0, 0, 0]
      this.recharge = 0
      this.optional = false
      this.tags = []
      this.actions = []
      this.bonuses = []
      this.synergies = []
      this.deployables = []
      this.counters = []
      this.clocks = []
      this.isEdit = false
    },
  },
})
