var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('editor-base', {
    attrs: {
      "itemKey": "reserves",
      "checkDupes": ['id', 'name'],
      "checkEmpty": ['id', 'name', 'label', 'type']
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-toolbar', {
          staticClass: "white--text text-h6",
          attrs: {
            "dense": "",
            "color": "primary"
          }
        }, [_vm._v(_vm._s(item.name))]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('id-input', {
          model: {
            value: item.id,
            callback: function callback($$v) {
              _vm.$set(item, "id", $$v);
            },
            expression: "item.id"
          }
        })], 1), _c('v-col', [_c('v-text-field', {
          attrs: {
            "label": "Name"
          },
          model: {
            value: item.name,
            callback: function callback($$v) {
              _vm.$set(item, "name", $$v);
            },
            expression: "item.name"
          }
        })], 1), _c('v-col', [_c('v-text-field', {
          attrs: {
            "label": "Label"
          },
          model: {
            value: item.label,
            callback: function callback($$v) {
              _vm.$set(item, "label", $$v);
            },
            expression: "item.label"
          }
        })], 1), _c('v-col', [_c('v-select', {
          attrs: {
            "label": "Reserve Type",
            "items": ['Mech', 'Tactical', 'Resource', 'Bonus']
          },
          model: {
            value: item.type,
            callback: function callback($$v) {
              _vm.$set(item, "type", $$v);
            },
            expression: "item.type"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-switch', {
          attrs: {
            "label": "Consumable"
          },
          model: {
            value: item.consumable,
            callback: function callback($$v) {
              _vm.$set(item, "consumable", $$v);
            },
            expression: "item.consumable"
          }
        })], 1)], 1), _c('v-row', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-col', [_c('rich-text-editor', {
          attrs: {
            "title": "Description"
          },
          model: {
            value: item.description,
            callback: function callback($$v) {
              _vm.$set(item, "description", $$v);
            },
            expression: "item.description"
          }
        })], 1)], 1), _c('v-row', [_c('v-col', [_c('synergy-selector', {
          attrs: {
            "item": item
          }
        })], 1)], 1), _c('v-row', [_c('v-col', [_c('special-equipment-selector', {
          attrs: {
            "item": item
          }
        })], 1), _c('v-col', [_c('integrated-selector', {
          attrs: {
            "item": item
          }
        })], 1)], 1), _c('v-row', [_c('v-col', [_c('i-action-builder', {
          attrs: {
            "item": item
          }
        })], 1), _c('v-col', [_c('i-bonus-builder', {
          attrs: {
            "item": item
          }
        })], 1)], 1), _c('v-row', [_c('v-col', [_c('i-counter-builder', {
          attrs: {
            "item": item
          }
        })], 1), _c('v-col', [_c('i-deployable-builder', {
          attrs: {
            "item": item
          }
        })], 1)], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }