var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "fullscreen": ""
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "text-h6",
    attrs: {
      "dense": "",
      "color": "teal darken-4"
    }
  }, [_vm._v(" NPC System Editor "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', [_c('v-row', {
    attrs: {
      "justify": "space-around",
      "align": "end"
    }
  }, [_c('v-col', [_c('id-input', {
    model: {
      value: _vm.id,
      callback: function callback($$v) {
        _vm.id = $$v;
      },
      expression: "id"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "hide-details": ""
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "label": "Size",
      "items": _vm.weaponSizes,
      "hide-details": ""
    },
    model: {
      value: _vm.weapon_size,
      callback: function callback($$v) {
        _vm.weapon_size = $$v;
      },
      expression: "weapon_size"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "label": "Type",
      "items": _vm.weaponTypes,
      "hide-details": ""
    },
    model: {
      value: _vm.weapon_type,
      callback: function callback($$v) {
        _vm.weapon_type = $$v;
      },
      expression: "weapon_type"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Recharge",
      "type": "number",
      "hide-details": "",
      "outlined": "",
      "dense": "",
      "clearable": ""
    },
    model: {
      value: _vm.recharge,
      callback: function callback($$v) {
        _vm.recharge = $$v;
      },
      expression: "recharge"
    }
  })], 1), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.npcClass || _vm.npcTemplate,
      expression: "npcClass || npcTemplate"
    }],
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    attrs: {
      "inset": "",
      "value": _vm.optional,
      "mandatory": "",
      "dense": "",
      "hide-details": "",
      "label": "".concat(_vm.npcClass ? 'Class' : 'Template', " Feature")
    },
    model: {
      value: _vm.optional,
      callback: function callback($$v) {
        _vm.optional = $$v;
      },
      expression: "optional"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('rich-text-editor', {
    attrs: {
      "title": "Effect",
      "npc": ""
    },
    model: {
      value: _vm.effect,
      callback: function callback($$v) {
        _vm.effect = $$v;
      },
      expression: "effect"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('rich-text-editor', {
    attrs: {
      "title": "On Attack Effect"
    },
    model: {
      value: _vm.on_attack,
      callback: function callback($$v) {
        _vm.on_attack = $$v;
      },
      expression: "on_attack"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('rich-text-editor', {
    attrs: {
      "title": "On Hit Effect"
    },
    model: {
      value: _vm.on_hit,
      callback: function callback($$v) {
        _vm.on_hit = $$v;
      },
      expression: "on_hit"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('rich-text-editor', {
    attrs: {
      "title": "On Crit Effect"
    },
    model: {
      value: _vm.on_crit,
      callback: function callback($$v) {
        _vm.on_crit = $$v;
      },
      expression: "on_crit"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-5"
  }), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('damage-selector', {
    attrs: {
      "item": this,
      "npc": ""
    }
  })], 1), _c('v-col', [_c('range-selector', {
    attrs: {
      "item": this,
      "npc": ""
    }
  })], 1), _c('v-col', [_c('tiered-stat-input', {
    attrs: {
      "value": _vm.attack_bonus,
      "title": "Attack Bonus"
    }
  })], 1), _c('v-col', [_c('tiered-stat-input', {
    attrs: {
      "value": _vm.accuracy,
      "title": "Accuracy"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-5"
  }), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('i-action-builder', {
    attrs: {
      "npc": "",
      "item": this
    }
  })], 1), _c('v-col', [_c('i-deployable-builder', {
    attrs: {
      "npc": "",
      "item": this
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('i-synergy-builder', {
    attrs: {
      "npc": "",
      "item": this
    }
  })], 1), _c('v-col', [_c('i-counter-builder', {
    attrs: {
      "item": this
    }
  })], 1), _c('v-col', [_c('i-clock-builder', {
    attrs: {
      "item": this
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('i-bonus-builder', {
    attrs: {
      "npc": "",
      "item": this
    }
  })], 1), _c('v-col', [_c('tag-selector', {
    attrs: {
      "npc": "",
      "item": this
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("cancel")]), _c('v-spacer'), _vm.isEdit ? _c('v-btn', {
    attrs: {
      "color": "error darken-2"
    },
    on: {
      "click": _vm.remove
    }
  }, [_vm._v("Delete")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": "success darken-2",
      "disabled": !_vm.confirmOK
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.isEdit ? 'save' : 'confirm') + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }