





























































import Vue from 'vue'
import { synergyLocations, activationTypes } from '@/assets/enums'
import { ILCPContent, LCPContentKeys } from '@tenebrae-press/lancer-types'

function getDuplicateProperties(arr: Record<string, unknown>[], prop: string) {
  let sorted = arr.map(x => x[prop]).sort()
  let results = []
  for (let i = 0; i < sorted.length - 1; i++) {
    if (sorted[i + 1] == sorted[i]) {
      results.push(sorted[i])
    }
  }
  return results
}

function getEmptyProperties(arr: Array<Record<string, unknown>>, prop: string) {
  return arr.filter(x => !x[prop] || !(x[prop] as []).length)
}

export default Vue.extend({
  name: 'editor-base',
  props: {
    itemKey: {
      type: String as () => LCPContentKeys,
    },
    checkDupes: { type: Array as () => Array<string> },
    checkEmpty: { type: Array as () => Array<string> },
  },
  data: () => ({
    synergyLocations: synergyLocations,
    activationTypes: activationTypes,
  }),
  computed: {
    loaded(): boolean {
      return this.$store.getters.loaded
    },
    lcp(): ILCPContent {
      return this.$store.getters.lcp
    },
    errors(): string[] {
      const arr: string[] = []
      if (!this.lcp[this.itemKey] || !this.lcp[this.itemKey]?.length) return arr

      let content: Array<Record<string, unknown>> = this.lcp[this.itemKey] ?? []

      this.checkDupes.forEach((prop: string) => {
        getDuplicateProperties(content, prop).forEach(p => {
          arr.push(`Duplicate ${prop} detected:   ${p}`)
        })
      })
      this.checkEmpty.forEach((prop: string) => {
        getEmptyProperties(content, prop).forEach(p => {
          arr.push(`Item with missing ${prop} field (${p.id || p.name || '--'})`)
        })
      })
      return arr
    },
  },
  methods: {
    lcpContentKey(key: LCPContentKeys): Array<{ id?: string; name?: string }> {
      return this.lcp[key] ?? []
    },
    addNew() {
      if (!this.lcp[this.itemKey]) {
        this.$set(this.lcp, this.itemKey, [])
      }

      ;(this.lcp[this.itemKey] as unknown as unknown[]).push({} as unknown)
    },
    duplicateItem(item: { id?: string }) {
      ;(this.lcp[this.itemKey] as unknown as unknown[]).push(JSON.parse(JSON.stringify(item)))
    },
    deleteItem(item: { id?: string }) {
      ;(this.lcp[this.itemKey] as unknown as Array<{ id: string }>).splice(
        (this.lcp[this.itemKey] as unknown as Array<{ id: string }>).findIndex(
          x => x.id === item.id
        ),
        1
      )
    },
    exportJson() {
      const blob = new Blob([JSON.stringify(this.lcp[this.itemKey])])
      const elem = window.document.createElement('a')
      elem.href = window.URL.createObjectURL(blob)
      elem.download = `${this.itemKey}.json`
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    },
    importJson() {
      if (this.$refs.fileUpload) (this.$refs.fileUpload as HTMLElement).click()
    },
    importFile(evt: Event) {
      const file = (evt.target as HTMLInputElement).files?.item(0)
      if (!file) return
      const reader = new FileReader()

      reader.onload = e =>
        this.$set(this.lcp, this.itemKey, JSON.parse(e?.target?.result?.toString() || ''))
      reader.readAsText(file)
    },
  },
})
