
















































import { ILCPContent } from '@tenebrae-press/lancer-types'
import Vue from 'vue'

export default Vue.extend({
  name: 'table-editor',
  props: ['itemKey', 'checkDupes', 'checkEmpty'],
  data: () => ({
    tables: [
      {
        key: 'pilot_names',
        name: 'Pilot Names',
        location: 'Pilot names, rollable on the first Create New Pilot screen',
      },
      {
        key: 'pilot_callsigns',
        name: 'Pilot Callsigns',
        location: 'Pilot callsigns, rollable on the first Create New Pilot screen',
      },
      {
        key: 'mech_names',
        name: 'Mech Names',
        location: 'Mech names, rollable on the Add New Mech to Hangar screen',
      },
      {
        key: 'quirks',
        name: 'Quirks',
        location: 'Rollable Quirks table for Flash Cloned pilots',
      },
    ],
  }),
  computed: {
    loaded(): boolean {
      return this.$store.getters.loaded
    },
    lcp(): ILCPContent {
      return this.$store.getters.lcp
    },
  },
  created() {
    if (!this.lcp.tables) {
      const newTables: Record<string, Array<string>> = {}
      for (const { key } of this.tables) {
        newTables[key] = []
      }
      this.$set(this.lcp, 'tables', newTables)
    } else {
      for (const { key } of this.tables) {
        this.lcp.tables[key] = []
      }
    }
  },
  methods: {
    lcpTables(key: string): Array<string> {
      return this.lcp.tables?.[key] ?? []
    },
    addNew(key: string) {
      this.lcp.tables?.[key].push('')
    },
    deleteItem(key: string, index: number) {
      this.lcp.tables?.[key].splice(index, 1)
    },
    exportJson() {
      const blob = new Blob([JSON.stringify(this.lcp.tables)])
      const elem = window.document.createElement('a')
      elem.href = window.URL.createObjectURL(blob)
      elem.download = 'tables.json'
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    },
    importJson() {
      if (this.$refs.fileUpload) (this.$refs.fileUpload as HTMLElement).click()
    },
    importFile(evt: Event) {
      const file = (evt.target as HTMLInputElement).files?.item(0)
      if (!file) return
      const reader = new FileReader()

      reader.onload = e =>
        this.$set(this.lcp, 'tables', JSON.parse(e?.target?.result?.toString() || ''))
      reader.readAsText(file)
    },
  },
})
