var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "nav": "",
      "dense": ""
    }
  }, _vm._l(_vm.templates, function (c, i) {
    return _c('v-list-item', {
      key: "".concat(c.id || 'new', "_").concat(i),
      class: _vm.selected && _vm.selected.id === c.id ? 'primary darken-3' : '',
      attrs: {
        "selectable": ""
      },
      on: {
        "click": function click($event) {
          _vm.selected = c;
        }
      }
    }, [_c('v-list-item-content', {
      staticClass: "mt-n2"
    }, [_c('v-list-item-title', [_c('span', {
      staticClass: "text-h6 mr-1"
    }, [_vm._v(_vm._s(c.name))])]), _c('v-list-item-action-text', {
      staticClass: "mt-n2"
    }, [_vm._v(" " + _vm._s(_vm.getFeatures(c).length) + " base features / " + _vm._s(_vm.getFeatures(c, true).length) + " optional ")])], 1)], 1);
  }), 1), _c('v-divider', {
    staticClass: "my-2"
  }), _c('v-btn', {
    attrs: {
      "block": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.addNew
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" Add New NPC Class ")], 1)], 1), _c('v-col', [_vm.selected ? _c('v-container', [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-toolbar', {
    staticClass: "white--text text-h6",
    attrs: {
      "dense": "",
      "color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.selected.name) + " ")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "dense": "",
      "justify": "space-around",
      "align": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('id-input', {
    model: {
      value: _vm.selected.id,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "id", $$v);
      },
      expression: "selected.id"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "hide-details": "",
      "label": "Name",
      "dense": ""
    },
    model: {
      value: _vm.selected.name,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "name", $$v);
      },
      expression: "selected.name"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": "",
      "justify": "space-around",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('rich-text-editor', {
    attrs: {
      "title": "Description"
    },
    model: {
      value: _vm.selected.description,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "description", $$v);
      },
      expression: "selected.description"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('rich-text-editor', {
    attrs: {
      "title": "Detail"
    },
    model: {
      value: _vm.selected.detail,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "detail", $$v);
      },
      expression: "selected.detail"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-4"
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-simple-checkbox', {
    attrs: {
      "dense": "",
      "hide-details": "",
      "ripple": false
    },
    model: {
      value: _vm.selected.allowOptional,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "allowOptional", $$v);
      },
      expression: "selected.allowOptional"
    }
  })], 1), _c('v-col', [_c('div', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(" When choosing optional systems, the "), _c('b', [_vm._v(_vm._s(_vm.selected.name))]), _vm._v(" can also choose from the "), _c('b', [_vm._v(_vm._s(_vm.selected.name) + " Features")]), _vm._v(" list. ")])])], 1), _c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-simple-checkbox', {
    attrs: {
      "dense": "",
      "hide-details": "",
      "ripple": false
    },
    model: {
      value: _vm.selected.forceClassOptional,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "forceClassOptional", $$v);
      },
      expression: "selected.forceClassOptional"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" the "), _c('b', [_vm._v(_vm._s(_vm.selected.name))]), _vm._v(" chooses ")]), _c('v-col', {
    staticClass: "mx-2",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.selected.optionalClassMin,
      "type": "number",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.selected.optionalClassMin,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "optionalClassMin", $$v);
      },
      expression: "selected.optionalClassMin"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("to")]), _c('v-col', {
    staticClass: "mx-2",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.selected.optionalClassMax,
      "type": "number",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.selected.optionalClassMax,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "optionalClassMax", $$v);
      },
      expression: "selected.optionalClassMax"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("option(s) from the NPC's Class Features list")]), _c('v-col', {
    staticClass: "mx-2 mt-n2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "value": _vm.selected.optionalClassPerTier,
      "items": [{
        text: 'when choosing optional systems.',
        value: false
      }, {
        text: 'per Tier.',
        value: true
      }],
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.selected.optionalClassPerTier,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "optionalClassPerTier", $$v);
      },
      expression: "selected.optionalClassPerTier"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-simple-checkbox', {
    attrs: {
      "dense": "",
      "hide-details": "",
      "ripple": false
    },
    model: {
      value: _vm.selected.forceOptional,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "forceOptional", $$v);
      },
      expression: "selected.forceOptional"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" the "), _c('b', [_vm._v(_vm._s(_vm.selected.name))]), _vm._v(" chooses ")]), _c('v-col', {
    staticClass: "mx-2",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.selected.optionalMin,
      "type": "number",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.selected.optionalMin,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "optionalMin", $$v);
      },
      expression: "selected.optionalMin"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("to")]), _c('v-col', {
    staticClass: "mx-2",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.selected.optionalMax,
      "type": "number",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.selected.optionalMax,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "optionalMax", $$v);
      },
      expression: "selected.optionalMax"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("option(s) from the " + _vm._s(_vm.selected.name) + " Features list")]), _c('v-col', {
    staticClass: "mx-2 mt-n2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "value": _vm.selected.optionalPerTier,
      "items": [{
        text: 'when choosing optional systems.',
        value: false
      }, {
        text: 'per Tier.',
        value: true
      }],
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.selected.optionalPerTier,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "optionalPerTier", $$v);
      },
      expression: "selected.optionalPerTier"
    }
  })], 1)], 1)], 1), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selected.allowOptional || _vm.selected.forceOptional,
      expression: "selected.allowOptional || selected.forceOptional"
    }],
    staticClass: "mt-n3",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "dense": "",
      "hide-details": "",
      "rows": "1",
      "outlined": "",
      "auto-grow": "",
      "label": "Caveat (optional)"
    },
    model: {
      value: _vm.selected.caveat,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "caveat", $$v);
      },
      expression: "selected.caveat"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-toolbar', {
    attrs: {
      "dense": "",
      "color": "grey darken-3"
    }
  }, [_c('b', [_vm._v("BASE FEATURES")])]), _c('v-card-text', [_c('v-row', _vm._l(_vm.getFeatures(_vm.selected), function (item, i) {
    return _c('v-col', {
      key: "base_feature_".concat(i)
    }, [_c('v-btn', {
      attrs: {
        "block": "",
        "color": _vm.colorByType(item)
      },
      on: {
        "click": function click($event) {
          return _vm.openByType(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1);
  }), 1)], 1)], 1)], 1), _c('v-col', [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-toolbar', {
    attrs: {
      "dense": "",
      "color": "grey darken-3"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.selected.name) + " FEATURES")])]), _c('v-card-text', [_c('v-row', _vm._l(_vm.getFeatures(_vm.selected, true), function (item, i) {
    return _c('v-col', {
      key: "optional_feature_".concat(i)
    }, [_c('v-btn', {
      attrs: {
        "block": "",
        "color": _vm.colorByType(item)
      },
      on: {
        "click": function click($event) {
          return _vm.openByType(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1);
  }), 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "pink darken-4"
    },
    on: {
      "click": function click($event) {
        return _vm.newFeature('traits');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" add new trait ")], 1)], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "deep-orange darken-4"
    },
    on: {
      "click": function click($event) {
        return _vm.newFeature('weapons');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" add new weapon ")], 1)], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "green darken-2"
    },
    on: {
      "click": function click($event) {
        return _vm.newFeature('systems');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" add new system ")], 1)], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "teal darken-4"
    },
    on: {
      "click": function click($event) {
        return _vm.newFeature('reactions');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" add new reaction ")], 1)], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "amber darken-4"
    },
    on: {
      "click": function click($event) {
        return _vm.newFeature('protocols');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" add new protocol ")], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticStyle: {
      "min-height": "50px"
    }
  })], 1) : _c('div', [_c('v-row', {
    staticStyle: {
      "height": "50vh"
    },
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-h2 text--disabled",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("Select a Class")])], 1)], 1)], 1)], 1), _c('v-footer', {
    attrs: {
      "fixed": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "to": "/"
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")]), _vm._v(" back ")], 1), _c('v-spacer'), _c('input', {
    ref: "fileUpload",
    attrs: {
      "type": "file",
      "accept": ".json",
      "hidden": ""
    },
    on: {
      "change": _vm.importFile
    }
  }), _c('v-btn', {
    staticClass: "mx-1",
    attrs: {
      "outlined": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.exportJSON();
      }
    }
  }, [_vm._v("Export JSON File")]), _c('v-btn', {
    staticClass: "mx-1",
    attrs: {
      "outlined": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.importJSON();
      }
    }
  }, [_vm._v("Import JSON File")])], 1), _c('div', {
    staticStyle: {
      "height": "50px"
    }
  }), _c('npc-system-editor', {
    ref: "systems",
    attrs: {
      "npcTemplate": _vm.selected
    },
    on: {
      "save": function save($event) {
        return _vm.saveItem($event);
      },
      "remove": function remove($event) {
        return _vm.removeItem($event);
      }
    }
  }), _c('npc-trait-editor', {
    ref: "traits",
    attrs: {
      "npcTemplate": _vm.selected
    },
    on: {
      "save": function save($event) {
        return _vm.saveItem($event);
      },
      "remove": function remove($event) {
        return _vm.removeItem($event);
      }
    }
  }), _c('npc-reaction-editor', {
    ref: "reactions",
    attrs: {
      "npcTemplate": _vm.selected
    },
    on: {
      "save": function save($event) {
        return _vm.saveItem($event);
      },
      "remove": function remove($event) {
        return _vm.removeItem($event);
      }
    }
  }), _c('npc-protocol-editor', {
    ref: "protocols",
    attrs: {
      "npcTemplate": _vm.selected
    },
    on: {
      "save": function save($event) {
        return _vm.saveItem($event);
      },
      "remove": function remove($event) {
        return _vm.removeItem($event);
      }
    }
  }), _c('npc-weapon-editor', {
    ref: "weapons",
    attrs: {
      "npcTemplate": _vm.selected
    },
    on: {
      "save": function save($event) {
        return _vm.saveItem($event);
      },
      "remove": function remove($event) {
        return _vm.removeItem($event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }