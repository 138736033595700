

















































































































































import IProfileBuilder from '@/components/IProfileBuilder.vue'
import Lancer, {
  WeaponType,
  WeaponSize,
  IDamageData,
  IRangeData,
  IActionData,
  IBonusData,
  IDeployableData,
  IWeaponProfileData,
  ICounterData,
  ISynergyData,
  ITagData,
  IMechWeaponData,
} from '@tenebrae-press/lancer-types'

import Vue from 'vue'

type WeaponEditorData = {
  dialog: boolean
  weaponTypes: Array<WeaponType>
  weaponSizes: Array<WeaponSize>
  id: string
  name: string
  license: string
  license_level: number
  description: string
  effect: string
  on_attack: string
  on_hit: string
  on_crit: string
  mount: WeaponSize
  type: WeaponType
  cost: number
  barrage: boolean
  skirmish: boolean
  no_attack: boolean
  no_mods: boolean
  no_core_bonus: boolean
  no_bonus: boolean
  no_synergy: boolean
  damage: Array<IDamageData>
  range: Array<IRangeData>
  sp: number
  tags: Array<ITagData>
  actions: Array<IActionData>
  bonuses: Array<IBonusData>
  synergies: Array<ISynergyData>
  deployables: Array<IDeployableData>
  counters: Array<ICounterData>
  integrated: Array<string>
  special_equipment: Array<string>
  profiles: Array<IWeaponProfileData>
  isEdit: boolean
}

export default Vue.extend({
  name: 'weapon-editor',
  props: {
    manufacturer: { type: Object, required: false },
    licenses: { type: Array, required: false, default: () => [] },
  },
  components: {
    IProfileBuilder,
  },
  data: (): WeaponEditorData => ({
    dialog: false,
    weaponTypes: Lancer.WEAPON_TYPES,
    weaponSizes: Lancer.WEAPON_SIZES,
    id: '',
    name: '',
    license: '',
    license_level: 1,
    description: '',
    effect: '',
    on_attack: '',
    on_hit: '',
    on_crit: '',
    mount: 'Auxiliary',
    type: 'Melee',
    cost: 1,
    barrage: true,
    skirmish: true,
    no_attack: false,
    no_mods: false,
    no_core_bonus: false,
    no_bonus: false,
    no_synergy: false,
    damage: [],
    range: [],
    sp: 0,
    tags: [],
    actions: [],
    bonuses: [],
    synergies: [],
    deployables: [],
    counters: [],
    integrated: [],
    special_equipment: [],
    profiles: [],
    isEdit: false,
  }),
  computed: {
    confirmOK(): boolean {
      return !!this.id && !!this.name && !!this.mount
    },
    source(): string {
      if (this.manufacturer) return this.manufacturer.id
      if (this.tags.some(x => x.id === 'tg_exotic')) return 'EXOTIC'
      return ''
    },
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    submit(): void {
      const e: IMechWeaponData = {
        id: this.id,
        name: this.name,
        source: this.source,
        license: this.license,
        license_level: Number(this.license_level),
        description: this.description,
        effect: this.effect,
        on_attack: this.on_attack ?? '',
        on_hit: this.on_hit ?? '',
        on_crit: this.on_crit ?? '',
        mount: this.mount,
        type: this.type,
        cost: this.cost,
        barrage: this.barrage,
        skirmish: this.skirmish,
        no_attack: this.no_attack,
        no_mods: this.no_mods,
        no_core_bonus: this.no_core_bonus,
        no_bonus: this.no_bonus,
        no_synergy: this.no_synergy,
        damage: this.damage,
        range: this.range,
        sp: this.sp,
        tags: this.tags,
        actions: this.actions,
        bonuses: this.bonuses,
        synergies: this.synergies,
        deployables: this.deployables,
        counters: this.counters,
        integrated: this.integrated,
        special_equipment: this.special_equipment,
        profiles: this.profiles,
      }
      this.$emit('save', e)
      this.reset()
      this.dialog = false
    },
    edit(weapon: IMechWeaponData): void {
      this.id = weapon.id
      this.name = weapon.name
      this.license = weapon.license
      this.license_level = Number(weapon.license_level)
      this.description = weapon.description ?? ''
      this.effect = weapon.effect ?? ''
      this.on_attack = weapon.on_attack ?? ''
      this.on_hit = weapon.on_hit ?? ''
      this.on_crit = weapon.on_crit ?? ''
      this.mount = weapon.mount
      this.type = weapon.type
      this.cost = weapon.cost ?? 0
      this.barrage = weapon.barrage ?? true
      this.skirmish = weapon.skirmish ?? true
      this.no_attack = false
      this.no_mods = false
      this.no_core_bonus = false
      this.no_bonus = false
      this.no_synergy = false
      this.damage = weapon.damage ?? []
      this.range = weapon.range ?? []
      this.sp = weapon.sp ?? 0
      this.tags = weapon.tags ?? []
      this.actions = weapon.actions ?? []
      this.bonuses = weapon.bonuses ?? []
      this.synergies = weapon.synergies ?? []
      this.deployables = weapon.deployables ?? []
      this.counters = weapon.counters ?? []
      this.integrated = weapon.integrated ?? []
      this.special_equipment = []
      this.profiles = weapon.profiles ?? []
      this.isEdit = true
      this.dialog = true
    },
    remove(): void {
      this.$emit('remove', this.id)
      this.dialog = false
    },
    reset(): void {
      this.id = ''
      this.name = ''
      this.license = ''
      this.license_level = 1
      this.description = ''
      this.effect = ''
      this.on_attack = ''
      this.on_hit = ''
      this.on_crit = ''
      this.mount = 'Auxiliary'
      this.type = 'Melee'
      this.cost = 1
      this.barrage = true
      this.skirmish = true
      this.no_attack = false
      this.no_mods = false
      this.no_core_bonus = false
      this.no_bonus = false
      this.no_synergy = false
      this.damage = []
      this.range = []
      this.sp = 0
      this.tags = []
      this.actions = []
      this.bonuses = []
      this.synergies = []
      this.deployables = []
      this.counters = []
      this.integrated = []
      this.special_equipment = []
      this.profiles = []
      this.isEdit = false
    },
  },
})
