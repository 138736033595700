var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._l(Object.keys(_vm.licensesByFrame), function (l, i) {
    return _c('v-card', {
      key: "license_rank_".concat(i),
      staticClass: "my-2"
    }, [_c('v-toolbar', {
      staticClass: "text-h6",
      attrs: {
        "dense": ""
      }
    }, [_vm._v(_vm._s(l))]), _c('v-card-text', _vm._l(_vm.itemsByLevel(_vm.licensesByFrame[l]), function (lvl, j) {
      return _c('v-row', {
        key: "license_".concat(i, "_level_").concat(j)
      }, [_c('v-col', {
        staticClass: "text-h5 text-center",
        attrs: {
          "cols": "1"
        }
      }, [_vm._v("LL" + _vm._s(j))]), _c('v-col', [_c('v-row', _vm._l(_vm.itemsByLevel(_vm.licensesByFrame[l])[j], function (item, k) {
        return _c('v-col', {
          key: "license_".concat(i, "_level_").concat(j, "_item_").concat(k)
        }, [_c('v-btn', {
          attrs: {
            "block": "",
            "color": _vm.colorByType(item)
          },
          on: {
            "click": function click($event) {
              return _vm.openByType(item);
            }
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1);
      }), 1)], 1)], 1);
    }), 1)], 1);
  }), _c('v-divider', {
    staticClass: "mt-4"
  }), _c('v-row', {
    staticClass: "mt-1",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "deep-purple darken-4"
    },
    on: {
      "click": function click($event) {
        return _vm.newItem('frames');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" add new frame ")], 1)], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "deep-orange darken-4"
    },
    on: {
      "click": function click($event) {
        return _vm.newItem('weapons');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" add new weapon ")], 1)], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "teal darken-4"
    },
    on: {
      "click": function click($event) {
        return _vm.newItem('systems');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" add new system ")], 1)], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "cyan darken-3"
    },
    on: {
      "click": function click($event) {
        return _vm.newItem('mods');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" add new mod ")], 1)], 1)], 1), _c('frame-editor', {
    ref: "frames",
    attrs: {
      "manufacturer": _vm.manufacturer
    },
    on: {
      "save": function save($event) {
        return _vm.saveItem('frames', $event);
      },
      "remove": function remove($event) {
        return _vm.removeItem('frames', $event);
      }
    }
  }), _c('system-editor', {
    ref: "systems",
    attrs: {
      "manufacturer": _vm.manufacturer,
      "licenses": _vm.licenseNames
    },
    on: {
      "save": function save($event) {
        return _vm.saveItem('systems', $event);
      },
      "remove": function remove($event) {
        return _vm.removeItem('systems', $event);
      }
    }
  }), _c('mod-editor', {
    ref: "mods",
    attrs: {
      "manufacturer": _vm.manufacturer,
      "licenses": _vm.licenseNames
    },
    on: {
      "save": function save($event) {
        return _vm.saveItem('mods', $event);
      },
      "remove": function remove($event) {
        return _vm.removeItem('mods', $event);
      }
    }
  }), _c('weapon-editor', {
    ref: "weapons",
    attrs: {
      "manufacturer": _vm.manufacturer,
      "licenses": _vm.licenseNames
    },
    on: {
      "save": function save($event) {
        return _vm.saveItem('weapons', $event);
      },
      "remove": function remove($event) {
        return _vm.removeItem('weapons', $event);
      }
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }