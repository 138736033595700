var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('editor-base', {
    attrs: {
      "itemKey": "talents",
      "checkDupes": ['id', 'name'],
      "checkEmpty": ['id', 'name', 'description', 'ranks']
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-toolbar', {
          staticClass: "white--text text-h6",
          attrs: {
            "dense": "",
            "color": "primary"
          }
        }, [_vm._v(_vm._s(item.name))]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('id-input', {
          model: {
            value: item.id,
            callback: function callback($$v) {
              _vm.$set(item, "id", $$v);
            },
            expression: "item.id"
          }
        })], 1), _c('v-col', [_c('v-text-field', {
          attrs: {
            "label": "Name"
          },
          model: {
            value: item.name,
            callback: function callback($$v) {
              _vm.$set(item, "name", $$v);
            },
            expression: "item.name"
          }
        })], 1), _c('v-col', [_c('v-text-field', {
          attrs: {
            "label": "Icon URL",
            "persistent-hint": "",
            "hint": "Must be .svg"
          },
          model: {
            value: item.icon_url,
            callback: function callback($$v) {
              _vm.$set(item, "icon_url", $$v);
            },
            expression: "item.icon_url"
          }
        })], 1)], 1), _c('v-row', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-text-field', {
          attrs: {
            "label": "Short Description"
          },
          model: {
            value: item.terse,
            callback: function callback($$v) {
              _vm.$set(item, "terse", $$v);
            },
            expression: "item.terse"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('rich-text-editor', {
          attrs: {
            "title": "Long Description"
          },
          model: {
            value: item.description,
            callback: function callback($$v) {
              _vm.$set(item, "description", $$v);
            },
            expression: "item.description"
          }
        })], 1), _c('v-col', {
          staticClass: "mt-2",
          attrs: {
            "cols": "12"
          }
        }, [_c('i-rank-builder', {
          attrs: {
            "item": item
          }
        })], 1)], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }