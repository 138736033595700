










































































































































import Lancer, {
  IActionData,
  IBonusData,
  ICounterData,
  IDamageData,
  IDeployableData,
  IManufacturerData,
  IRangeData,
  ISynergyData,
  ITagData,
  IWeaponModData,
  MountType,
  WeaponSize,
  WeaponType,
} from '@tenebrae-press/lancer-types'

import Vue from 'vue'

type ModEditorData = {
  dialog: boolean
  weaponType: Array<WeaponType>
  weaponSize: Array<WeaponSize>
  id: string
  name: string
  license: string
  license_level: number
  effect: string
  sp: number
  description: string
  allowed_types: Array<WeaponType>
  allowed_sizes: Array<MountType>
  restricted_types: Array<WeaponType>
  restricted_sizes: Array<MountType>
  tags: Array<ITagData>
  actions: Array<IActionData>
  bonuses: Array<IBonusData>
  synergies: Array<ISynergyData>
  deployables: Array<IDeployableData>
  counters: Array<ICounterData>
  integrated: string[]
  special_equipment: string[]
  added: {
    damage: Array<IDamageData>
    range: Array<IRangeData>
    tags: Array<ITagData>
  }
  isEdit: boolean
}

export default Vue.extend({
  name: 'mod-editor',
  props: {
    manufacturer: { type: Object as () => IManufacturerData, required: false },
    licenses: { type: Array, required: false, default: () => [] },
  },

  data: (): ModEditorData => ({
    dialog: false,
    weaponType: Lancer.WEAPON_TYPES,
    weaponSize: Lancer.WEAPON_SIZES,
    id: '',
    name: '',
    license: '',
    license_level: 1,
    effect: '',
    sp: 0,
    description: '',
    allowed_types: [],
    allowed_sizes: [],
    restricted_types: [],
    restricted_sizes: [],
    tags: [],
    actions: [],
    bonuses: [],
    synergies: [],
    deployables: [],
    counters: [],
    integrated: [],
    special_equipment: [],
    added: {
      damage: [],
      range: [],
      tags: [],
    },
    isEdit: false,
  }),
  computed: {
    confirmOK(): boolean {
      return !!this.id && !!this.name
    },
    source(): string {
      if (this.manufacturer) return this.manufacturer.id
      if (this.tags.some(x => x.id === 'tg_exotic')) return 'EXOTIC'
      return ''
    },
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    submit(): void {
      const e: IWeaponModData = {
        id: this.id,
        name: this.name,
        source: this.source,
        license: this.license,
        license_level: Number(this.license_level),
        effect: this.effect,
        sp: this.sp,
        description: this.description,
        allowed_types: this.allowed_types,
        allowed_sizes: this.allowed_sizes,
        restricted_types: this.restricted_types,
        restricted_sizes: this.restricted_sizes,
        added_damage: this.added.damage,
        added_range: this.added.range,
        added_tags: this.added.tags,
        tags: this.tags,
        actions: this.actions,
        bonuses: this.bonuses,
        synergies: this.synergies,
        deployables: this.deployables,
        counters: this.counters,
        integrated: this.integrated,
        special_equipment: this.special_equipment,
      }
      this.$emit('save', e)
      this.reset()
      this.dialog = false
    },
    edit(mod: IWeaponModData): void {
      this.id = mod.id
      this.name = mod.name
      this.license = mod.license
      this.license_level = Number(mod.license_level)
      this.effect = mod.effect ?? ''
      this.sp = mod.sp ?? 0
      this.description = mod.description ?? ''
      this.allowed_types = mod.allowed_types ?? []
      this.allowed_sizes = mod.allowed_sizes ?? []
      this.restricted_types = mod.restricted_types ?? []
      this.restricted_sizes = mod.restricted_sizes ?? []
      this.added = {
        damage: mod.added_damage ?? [],
        range: mod.added_range ?? [],
        tags: mod.added_tags ?? [],
      }
      this.tags = mod.tags ?? []
      this.actions = mod.actions ?? []
      this.bonuses = mod.bonuses ?? []
      this.synergies = mod.synergies ?? []
      this.deployables = mod.deployables ?? []
      this.counters = mod.counters ?? []
      this.integrated = mod.integrated ?? []
      this.special_equipment = []
      this.isEdit = true
      this.dialog = true
    },
    remove(): void {
      this.$emit('remove', this.id)
      this.dialog = false
    },
    reset(): void {
      this.id = ''
      this.name = ''
      this.license = ''
      this.license_level = 1
      this.effect = ''
      this.sp = 0
      this.description = ''
      this.allowed_types = []
      this.allowed_sizes = []
      this.restricted_types = []
      this.restricted_sizes = []
      this.added = {
        damage: [],
        range: [],
        tags: [],
      }
      this.tags = []
      this.actions = []
      this.bonuses = []
      this.synergies = []
      this.deployables = []
      this.counters = []
      this.integrated = []
      this.special_equipment = []
      this.isEdit = false
    },
  },
})
