





































































































































































































import Vue from 'vue'
import { manufacturers } from 'lancer-data'

import ColorSelector from '@/components/ColorSelector.vue'
import CoreBonusEditor from './components/CoreBonus.vue'
import LicenseEditor from './components/Licenses.vue'
import SourcelessEquipment from './components/SourcelessEquipment.vue'
import {
  ICoreBonusData,
  IFrameData,
  IManufacturerData,
  IMechSystemData,
  IMechWeaponData,
  IWeaponModData,
} from '@tenebrae-press/lancer-types'
import { ILCPContent } from '@tenebrae-press/lancer-types/src/lcp'

const typedManufacturers = manufacturers as unknown as Array<IManufacturerData>

type ManufacturerEditorData = {
  panels: number
  core_manufacturers: Array<IManufacturerData>
  selected?: IManufacturerData | 'none' | null
  importKey: string
}

export default Vue.extend({
  name: 'manufacturer-editor',
  components: {
    CoreBonusEditor,
    LicenseEditor,
    SourcelessEquipment,
    ColorSelector,
  },
  data: (): ManufacturerEditorData => ({
    panels: 0,
    core_manufacturers: typedManufacturers,
    selected: null,
    importKey: '',
  }),
  computed: {
    lcp(): ILCPContent {
      return this.$store.getters.lcp
    },
    allManufacturers(): Array<IManufacturerData> {
      const local: Array<IManufacturerData> = this.lcp.manufacturers ? this.lcp.manufacturers : []
      return [
        ...new Map(local.concat(this.core_manufacturers).map(item => [item.id, item])).values(),
      ]
    },
    liteColorRaw: {
      get() {
        return this.selected !== 'none' ? this.selected?.light : undefined
      },
      set(v: { hex: string }) {
        if (this.selected && this.selected !== 'none') {
          this.selected.light = v['hex']
        }
      },
    },
    darkColorRaw: {
      get() {
        return this.selected !== 'none' ? this.selected?.dark : undefined
      },
      set(v: { hex: string }) {
        if (this.selected && this.selected !== 'none') {
          this.selected.dark = v['hex']
        }
      },
    },
  },
  methods: {
    addNew() {
      if (!this.lcp.manufacturers) {
        this.$set(this.lcp, 'manufacturers', [])
      }
      this.lcp.manufacturers?.push({
        id: 'new',
        name: 'New Manufacturer',
        light: '#ff0000',
        dark: '#ff0000',
        description: '',
        quote: '',
      })
    },
    itemsByMID(
      id: string,
      type: 'core_bonuses' | 'frames' | 'weapons' | 'systems' | 'mods'
    ): Array<IMechWeaponData | IFrameData | ICoreBonusData | IMechSystemData | IWeaponModData> {
      if (!this.lcp[type]) {
        return []
      } else {
        switch (type) {
          case 'core_bonuses':
            return (
              this.lcp.core_bonuses?.filter(x => x.source === id || x.source === 'EXOTIC') ?? []
            )
          case 'frames':
            return this.lcp.frames?.filter(x => x.source === id || x.source === 'EXOTIC') ?? []
          case 'weapons':
            return this.lcp.weapons?.filter(x => x.source === id || x.source === 'EXOTIC') ?? []
          case 'systems':
            return this.lcp.systems?.filter(x => x.source === id || x.source === 'EXOTIC') ?? []
          case 'mods':
            return this.lcp.mods?.filter(x => x.source === id || x.source === 'EXOTIC') ?? []
        }
      }
    },
    isCore(id: string) {
      return this.core_manufacturers.map(x => x.id).some((y: string) => y === id)
    },
    exportJSON(itemKey: keyof ILCPContent) {
      const blob = new Blob([JSON.stringify(this.lcp[itemKey])])
      const elem = window.document.createElement('a')
      elem.href = window.URL.createObjectURL(blob)
      elem.download = `${itemKey}.json`
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    },
    importJSON(itemKey: string) {
      this.importKey = itemKey
      if (this.$refs.fileUpload) (this.$refs.fileUpload as HTMLElement).click()
    },
    importFile(evt: Event) {
      const file = (evt.target as HTMLInputElement).files?.item(0)
      if (!file) return
      const reader = new FileReader()

      reader.onload = e =>
        this.$set(this.lcp, this.importKey, JSON.parse(e?.target?.result?.toString() || ''))
      reader.readAsText(file)
    },
  },
})
