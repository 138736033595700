var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('editor-base', {
    attrs: {
      "itemKey": "statuses",
      "checkDupes": ['name'],
      "checkEmpty": ['name', 'effects', 'type', 'scope']
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-toolbar', {
          staticClass: "white--text text-h6",
          attrs: {
            "dense": "",
            "color": "primary"
          }
        }, [_vm._v(_vm._s(item.name))]), _c('v-card-text', [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('v-text-field', {
          attrs: {
            "label": "Name"
          },
          model: {
            value: item.name,
            callback: function callback($$v) {
              _vm.$set(item, "name", $$v);
            },
            expression: "item.name"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('v-text-field', {
          attrs: {
            "label": "Icon URL"
          },
          model: {
            value: item.icon_url,
            callback: function callback($$v) {
              _vm.$set(item, "icon_url", $$v);
            },
            expression: "item.icon_url"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "2"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "Type",
            "items": ['Status', 'Condition']
          },
          model: {
            value: item.type,
            callback: function callback($$v) {
              _vm.$set(item, "type", $$v);
            },
            expression: "item.type"
          }
        })], 1), _c('v-select', {
          attrs: {
            "label": "Scope",
            "item-text": "name",
            "item-value": "value",
            "items": [{
              name: 'Mech',
              value: 'Mech'
            }, {
              name: 'Pilot',
              value: 'Pilot'
            }, {
              name: 'Both',
              value: ''
            }]
          },
          model: {
            value: item.exclusive,
            callback: function callback($$v) {
              _vm.$set(item, "exclusive", $$v);
            },
            expression: "item.exclusive"
          }
        })], 1), _c('v-text-field', {
          attrs: {
            "label": "Short Description"
          },
          model: {
            value: item.terse,
            callback: function callback($$v) {
              _vm.$set(item, "terse", $$v);
            },
            expression: "item.terse"
          }
        }), _c('rich-text-editor', {
          attrs: {
            "title": "Effects"
          },
          model: {
            value: item.effects,
            callback: function callback($$v) {
              _vm.$set(item, "effects", $$v);
            },
            expression: "item.effects"
          }
        })], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }