












































































import Lancer, {
  IActionData,
  IBonusData,
  ICounterData,
  IDeployableData,
  IMechSystemData,
  ISynergyData,
  ITagData,
  SystemType,
} from '@tenebrae-press/lancer-types'

import Vue from 'vue'

type SystemEditorData = {
  dialog: boolean
  systemTypes: Array<SystemType>
  id: string
  name: string
  license: string
  license_level: number
  effect: string
  type: SystemType
  sp: number
  description: string
  tags: Array<ITagData>
  actions: Array<IActionData>
  bonuses: Array<IBonusData>
  synergies: Array<ISynergyData>
  deployables: Array<IDeployableData>
  counters: Array<ICounterData>
  integrated: Array<string>
  special_equipment: Array<string>
  isEdit: boolean
}

export default Vue.extend({
  name: 'system-editor',
  props: {
    manufacturer: { type: Object, required: false },
    licenses: { type: Array, required: false, default: () => [] },
  },

  data: (): SystemEditorData => ({
    dialog: false,
    systemTypes: Lancer.SYSTEM_TYPES,
    id: '',
    name: '',
    license: '',
    license_level: 1,
    effect: '',
    type: 'System',
    sp: 0,
    description: '',
    tags: [],
    actions: [],
    bonuses: [],
    synergies: [],
    deployables: [],
    counters: [],
    integrated: [],
    special_equipment: [],
    isEdit: false,
  }),
  computed: {
    confirmOK(): boolean {
      return !!this.id && !!this.name
    },
    source(): string {
      if (this.manufacturer) return this.manufacturer.id
      if (this.tags.some(x => x.id === 'tg_exotic')) return 'EXOTIC'
      return ''
    },
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    submit(): void {
      const e: IMechSystemData = {
        id: this.id,
        name: this.name,
        source: this.source,
        license: this.license,
        license_level: Number(this.license_level),
        effect: this.effect,
        type: this.type,
        sp: this.sp,
        description: this.description,
        tags: this.tags,
        actions: this.actions,
        bonuses: this.bonuses,
        synergies: this.synergies,
        deployables: this.deployables,
        counters: this.counters,
        integrated: this.integrated,
        special_equipment: this.special_equipment,
      }
      this.$emit('save', e)
      this.reset()
      this.dialog = false
    },
    edit(system: IMechSystemData): void {
      this.id = system.id
      this.name = system.name ?? ''
      this.license = system.license
      this.license_level = Number(system.license_level)
      this.effect = system.effect ?? ''
      this.type = system.type ?? 'System'
      this.sp = Number(system.sp) ?? 0
      this.description = system.description ?? ''
      this.tags = system.tags ?? []
      this.actions = system.actions ?? []
      this.bonuses = system.bonuses ?? []
      this.synergies = system.synergies ?? []
      this.deployables = system.deployables ?? []
      this.counters = system.counters ?? []
      this.integrated = system.integrated ?? []
      this.special_equipment = []
      this.isEdit = true
      this.dialog = true
    },
    remove(): void {
      this.$emit('remove', this.id)
      this.dialog = false
    },
    reset(): void {
      this.id = ''
      this.name = ''
      this.license = ''
      this.license_level = 1
      this.effect = ''
      this.type = 'System'
      this.sp = 0
      this.description = ''
      this.tags = []
      this.actions = []
      this.bonuses = []
      this.synergies = []
      this.deployables = []
      this.counters = []
      this.integrated = []
      this.special_equipment = []
      this.isEdit = false
    },
  },
})
