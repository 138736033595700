var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "nav": "",
      "dense": ""
    }
  }, _vm._l(_vm.classes, function (c, i) {
    return _c('v-list-item', {
      key: "".concat(c.id || 'new', "_").concat(i),
      class: _vm.selected && _vm.selected.id === c.id ? 'primary darken-3' : '',
      attrs: {
        "selectable": ""
      },
      on: {
        "click": function click($event) {
          _vm.selected = c;
        }
      }
    }, [_c('v-list-item-content', {
      staticClass: "mt-n2"
    }, [_c('v-list-item-title', [_c('span', {
      staticClass: "text-h6 mr-1"
    }, [_vm._v(_vm._s(c.name))])]), _c('v-list-item-action-text', {
      staticClass: "mt-n2"
    }, [_vm._v(" " + _vm._s(_vm.getFeatures(c).length) + " base features / " + _vm._s(_vm.getFeatures(c, true).length) + " optional ")])], 1)], 1);
  }), 1), _c('v-divider', {
    staticClass: "my-2"
  }), _c('v-btn', {
    attrs: {
      "block": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.addNew
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" Add New NPC Class ")], 1)], 1), _c('v-col', [_vm.selected ? _c('v-container', [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-toolbar', {
    staticClass: "white--text text-h6",
    attrs: {
      "dense": "",
      "color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.selected.name) + " ")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "dense": "",
      "justify": "space-around",
      "align": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('id-input', {
    model: {
      value: _vm.selected.id,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "id", $$v);
      },
      expression: "selected.id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "hide-details": "",
      "label": "Name",
      "dense": ""
    },
    model: {
      value: _vm.selected.name,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "name", $$v);
      },
      expression: "selected.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Role",
      "outlined": "",
      "items": _vm.roles,
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.selected.role,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "role", $$v);
      },
      expression: "selected.role"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": "",
      "justify": "space-around",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "Short Description",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "rows": "2"
    },
    model: {
      value: _vm.selected.info.terse,
      callback: function callback($$v) {
        _vm.$set(_vm.selected.info, "terse", $$v);
      },
      expression: "selected.info.terse"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('rich-text-editor', {
    attrs: {
      "title": "Flavor"
    },
    model: {
      value: _vm.selected.info.flavor,
      callback: function callback($$v) {
        _vm.$set(_vm.selected.info, "flavor", $$v);
      },
      expression: "selected.info.flavor"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('rich-text-editor', {
    attrs: {
      "title": "Tactics"
    },
    model: {
      value: _vm.selected.info.tactics,
      callback: function callback($$v) {
        _vm.$set(_vm.selected.info, "tactics", $$v);
      },
      expression: "selected.info.tactics"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "mt-3 mb-5"
  }), _c('v-row', [_vm._l(Object.keys(_vm.selected.stats), function (key) {
    return _c('v-col', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: key !== 'size',
        expression: "key !== 'size'"
      }],
      key: "stat_".concat(key),
      staticClass: "pa-1",
      attrs: {
        "cols": "2"
      }
    }, [_c('tiered-stat-input', {
      attrs: {
        "title": key
      },
      model: {
        value: _vm.selected.stats[key],
        callback: function callback($$v) {
          _vm.$set(_vm.selected.stats, key, $$v);
        },
        expression: "selected.stats[key]"
      }
    })], 1);
  }), _c('v-col', {
    staticClass: "pa-1"
  }, [_c('tiered-size-input', {
    model: {
      value: _vm.selected.stats.size,
      callback: function callback($$v) {
        _vm.$set(_vm.selected.stats, "size", $$v);
      },
      expression: "selected.stats.size"
    }
  })], 1)], 2), _c('v-divider', {
    staticClass: "mb-3 mt-5"
  }), _c('v-row', [_c('v-col', [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-toolbar', {
    attrs: {
      "dense": "",
      "color": "grey darken-3"
    }
  }, [_c('b', [_vm._v("BASE FEATURES")])]), _c('v-card-text', [_c('v-row', _vm._l(_vm.getFeatures(_vm.selected), function (item, i) {
    return _c('v-col', {
      key: "base_feature_".concat(i)
    }, [_c('v-btn', {
      attrs: {
        "block": "",
        "color": _vm.colorByType(item)
      },
      on: {
        "click": function click($event) {
          return _vm.openByType(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1);
  }), 1)], 1)], 1)], 1), _c('v-col', [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-toolbar', {
    attrs: {
      "dense": "",
      "color": "grey darken-3"
    }
  }, [_c('b', [_vm._v("OPTIONAL FEATURES")])]), _c('v-card-text', [_c('v-row', _vm._l(_vm.getFeatures(_vm.selected, true), function (item, i) {
    return _c('v-col', {
      key: "optional_feature_".concat(i)
    }, [_c('v-btn', {
      attrs: {
        "block": "",
        "color": _vm.colorByType(item)
      },
      on: {
        "click": function click($event) {
          return _vm.openByType(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1);
  }), 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "pink darken-4"
    },
    on: {
      "click": function click($event) {
        return _vm.newFeature('traits');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" add new trait ")], 1)], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "deep-orange darken-4"
    },
    on: {
      "click": function click($event) {
        return _vm.newFeature('weapons');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" add new weapon ")], 1)], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "teal darken-4"
    },
    on: {
      "click": function click($event) {
        return _vm.newFeature('systems');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" add new system ")], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticStyle: {
      "min-height": "50px"
    }
  })], 1) : _c('div', [_c('v-row', {
    staticStyle: {
      "height": "50vh"
    },
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-h2 text--disabled",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("Select a Class")])], 1)], 1)], 1)], 1), _c('v-footer', {
    attrs: {
      "fixed": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "to": "/"
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")]), _vm._v(" back ")], 1), _c('v-spacer'), _c('input', {
    ref: "fileUpload",
    attrs: {
      "type": "file",
      "accept": ".json",
      "hidden": ""
    },
    on: {
      "change": _vm.importFile
    }
  }), _c('v-btn', {
    staticClass: "mx-1",
    attrs: {
      "outlined": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.exportJSON();
      }
    }
  }, [_vm._v("Export JSON File")]), _c('v-btn', {
    staticClass: "mx-1",
    attrs: {
      "outlined": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.importJSON();
      }
    }
  }, [_vm._v("Import JSON File")])], 1), _c('div', {
    staticStyle: {
      "height": "50px"
    }
  }), _c('npc-system-editor', {
    ref: "systems",
    attrs: {
      "npcClass": _vm.selected
    },
    on: {
      "save": function save($event) {
        return _vm.saveItem($event);
      },
      "remove": function remove($event) {
        return _vm.removeItem($event);
      }
    }
  }), _c('npc-trait-editor', {
    ref: "traits",
    attrs: {
      "npcClass": _vm.selected
    },
    on: {
      "save": function save($event) {
        return _vm.saveItem($event);
      },
      "remove": function remove($event) {
        return _vm.removeItem($event);
      }
    }
  }), _c('npc-weapon-editor', {
    ref: "weapons",
    attrs: {
      "npcClass": _vm.selected
    },
    on: {
      "save": function save($event) {
        return _vm.saveItem($event);
      },
      "remove": function remove($event) {
        return _vm.removeItem($event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }